import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { _ROUTES } from '../../resources/constants/routes';
import { RootState } from '../store';
import { ReduxStatus } from '../../enums';
import queryString from 'query-string';
import {
  CreateUser,
  ListResultCollection,
  UpdateUser,
  UserBase,
  UserListItem,
  UserListRequest,
} from '../../interfaces';
import { userRead, createUser, updateUser, removeUser } from './usersAPI';
import { apiSlice } from '../apiSlice';
import { ErrorHandler } from '../../helpers';

const usersEndpoints = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchUsers: builder.query<ListResultCollection<UserListItem>, UserListRequest>({
        keepUnusedDataFor: 0,
        query: (request) => `${_ROUTES.USERS}/?${queryString.stringify(request)}`,
        async onQueryStarted(_request, { queryFulfilled }) {
          await queryFulfilled.catch((err) => {
            ErrorHandler(err);
          });
        },
      }),
    };
  },
});

interface UserReadState {
  status: ReduxStatus;
  user?: UserBase;
}

interface UserCrudState {
  status: ReduxStatus;
}

export interface UsersState {
  userRead: UserReadState;
  userCrudState: UserCrudState;
}

const initialStateUsers: UsersState = {
  userRead: { status: ReduxStatus.Idle },
  userCrudState: { status: ReduxStatus.Idle },
};

export const readUser = createAsyncThunk<UserBase, string>(
  `${_ROUTES.USERS}`,
  async (request: string, { rejectWithValue }) => {
    return await userRead(request).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const userCreate = createAsyncThunk<number, CreateUser>(
  `${_ROUTES.USERS}/CREATE`,
  async (request: CreateUser, { rejectWithValue }) => {
    return await createUser(request).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const userUpdate = createAsyncThunk<void, { userId: number; user: UpdateUser }>(
  `${_ROUTES.USERS}/UPDATE`,
  async (request: { userId: number; user: UpdateUser }, { rejectWithValue }) => {
    return await updateUser(request.userId, request.user).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const userRemove = createAsyncThunk<void, number>(
  `${_ROUTES.USERS}/DELETE`,
  async (request: number, { rejectWithValue }) => {
    return await removeUser(request).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState: initialStateUsers,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(readUser.pending, (state) => {
        state.userRead.status = ReduxStatus.Loading;
      })
      .addCase(readUser.rejected, (state) => {
        state.userRead.status = ReduxStatus.Failed;
      })
      .addCase(readUser.fulfilled, (state, action) => {
        state.userRead.status = ReduxStatus.Succeeded;
        state.userRead.user = action.payload;
      })
      .addCase(userCreate.pending, (state) => {
        state.userCrudState.status = ReduxStatus.Loading;
      })
      .addCase(userCreate.rejected, (state) => {
        state.userCrudState.status = ReduxStatus.Failed;
      })
      .addCase(userCreate.fulfilled, (state) => {
        state.userCrudState.status = ReduxStatus.Succeeded;
      })
      .addCase(userUpdate.pending, (state) => {
        state.userCrudState.status = ReduxStatus.Loading;
      })
      .addCase(userUpdate.rejected, (state) => {
        state.userCrudState.status = ReduxStatus.Failed;
      })
      .addCase(userUpdate.fulfilled, (state) => {
        state.userCrudState.status = ReduxStatus.Succeeded;
      })
      .addCase(userRemove.pending, (state) => {
        state.userCrudState.status = ReduxStatus.Loading;
      })
      .addCase(userRemove.rejected, (state) => {
        state.userCrudState.status = ReduxStatus.Failed;
      })
      .addCase(userRemove.fulfilled, (state) => {
        state.userCrudState.status = ReduxStatus.Succeeded;
      });
  },
});

export const getUserState = (state: RootState) => state.users;

export default usersSlice.reducer;

export const { useFetchUsersQuery } = usersEndpoints;
