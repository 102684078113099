import React from 'react';
import { Grid } from '@mui/material';
import { ModalComponent, TextFieldComponent } from 'techsbcn-storybook';
import { _VALUES } from '../../../resources/constants/values';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { create } from '../../../redux/vehicles/vehiclesAPI';
import { _ROUTES } from '../../../resources/constants/routes';
import { useNavigate } from 'react-router-dom';

interface VehicleCreateModalProps {
  modalOpen: (open: boolean) => void;
  openModal: boolean;
}

export const VehicleCreateModal: React.FC<VehicleCreateModalProps> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const VehicleSchema = yup.object().shape({
    manufacturer: yup.string(),
    model: yup.string(),
    registrationNumber: yup.string(),
    provider: yup.string(),
  });
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(VehicleSchema),
  });

  const onSubmit = (data: any) => {
    setLoading(true);
    create(data)
      .then((id: number) => {
        props.modalOpen(false);
        setLoading(false);
        navigate(`${_ROUTES.VEHICLES}/${id}`);
      })
      .catch(() => {
        setLoading(false);
        props.modalOpen(false);
      });
  };

  return (
    <ModalComponent
      title={_VALUES.CREATE_VEHICLE}
      setShow={props.openModal}
      onHide={() => props.modalOpen(false)}
      cancelButton={{ label: _VALUES.CANCEL }}
      submitButton={{
        label: _VALUES.CONFIRM,
        form: 'vehicle-create-form',
        type: 'submit',
      }}
      loading={loading}
    >
      <form id="vehicle-create-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.MANUFACTURER}
                  placeholder={`${_VALUES.MANUFACTURER}...`}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  defaultValue={value}
                />
              )}
              name={'manufacturer'}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.MODEL}
                  placeholder={`${_VALUES.MODEL}...`}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  defaultValue={value}
                />
              )}
              name={'model'}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.REGISTRATIONNUMBER}
                  placeholder={`${_VALUES.REGISTRATIONNUMBER}...`}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  defaultValue={value}
                />
              )}
              name={'registrationNumber'}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.PROVIDER}
                  placeholder={`${_VALUES.PROVIDER}...`}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  defaultValue={value}
                />
              )}
              name={'provider'}
            />
          </Grid>
        </Grid>
      </form>
    </ModalComponent>
  );
};
