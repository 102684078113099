import { _ROUTES } from '../../resources/constants/routes';
import { VehicleListItem, VehicleListRequest, ListResultCollection } from '../../interfaces';
import { apiSlice } from '../apiSlice';
import queryString from 'query-string';
import { ErrorHandler } from '../../helpers';
import { ReduxStatus } from '../../enums';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { removeVehicle } from './vehiclesAPI';
import { RootState } from '../store';

const vehiclesEndpoints = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchVehicles: builder.query<ListResultCollection<VehicleListItem>, VehicleListRequest>({
        keepUnusedDataFor: 0,
        query: (request) => `${_ROUTES.VEHICLES}/?${queryString.stringify(request)}`,
        async onQueryStarted(_request, { queryFulfilled }) {
          await queryFulfilled.catch((err) => {
            ErrorHandler(err);
          });
        },
      }),
    };
  },
});

interface VehicleRemoveState {
  status: ReduxStatus;
}

export interface VehicleState {
  vehicleRemove: VehicleRemoveState;
}

const initialStateVehicles: VehicleState = {
  vehicleRemove: { status: ReduxStatus.Idle },
};

export const vehicleRemove = createAsyncThunk<boolean, number>(
  `${_ROUTES.VEHICLES}/DELETE`,
  async (request: number, { rejectWithValue }) => {
    return await removeVehicle(request).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState: initialStateVehicles,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(vehicleRemove.pending, (state) => {
        state.vehicleRemove.status = ReduxStatus.Loading;
      })
      .addCase(vehicleRemove.rejected, (state) => {
        state.vehicleRemove.status = ReduxStatus.Failed;
      })
      .addCase(vehicleRemove.fulfilled, (state) => {
        state.vehicleRemove.status = ReduxStatus.Succeeded;
      });
  },
});

export const getVehiclesState = (state: RootState) => state.vehicles;
export default vehiclesSlice.reducer;
export const { useFetchVehiclesQuery } = vehiclesEndpoints;
