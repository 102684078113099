import React from 'react';
import { Routes, Route, BrowserRouter, useLocation, Navigate, Outlet } from 'react-router-dom';
import Layout from './components/layouts/Layout';
import { getRoutePermision, checkPermission, GetToken, GetUserIdToken, useAppSelector } from './helpers';
import {
  Login,
  ResetPassword,
  ResetAccount,
  Vehicles,
  NotFound,
  Vehicle,
  Driver,
  Drivers,
  Company,
  Companies,
  Assignments,
  Assignment,
  ChecklistItems,
  Incidences,
  Incidence,
  BeautyShots,
} from './pages/index';
import { getAuthState } from './redux/auth/authSlice';
import { _ROUTES } from './resources/constants/routes';

function PrivateWrapper() {
  const { pathname } = useLocation();
  const roleState = useAppSelector(getAuthState).role;

  return GetUserIdToken() ? (
    checkPermission(pathname, roleState) ? (
      <Outlet />
    ) : (
      <NotFound />
    )
  ) : (
    <Navigate to={_ROUTES.LOGIN} />
  );
}

function PublicWrapper() {
  const roleState = useAppSelector(getAuthState).role;
  const initialroute = getRoutePermision([roleState]);
  return GetToken() ? initialroute ? <Navigate to={initialroute} /> : <Login /> : <Login />;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={_ROUTES.LOGIN} element={<PublicWrapper />} />
        <Route path={_ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path="/" element={<PublicWrapper />} />
        <Route path={`${_ROUTES.RESET_ACCOUNT}/:guid`} element={<ResetAccount />} />
        <Route element={<PrivateWrapper />}>
          <Route path={_ROUTES.COMPANIES} element={<Layout mainComponent={<Companies />} />} />
          <Route path={`${_ROUTES.COMPANIES}/:id`} element={<Layout mainComponent={<Company />} />} />
          <Route path={_ROUTES.DRIVERS} element={<Layout mainComponent={<Drivers />} />} />
          <Route path={`${_ROUTES.DRIVERS}/:id`} element={<Layout mainComponent={<Driver />} />} />
          <Route path={_ROUTES.VEHICLES} element={<Layout mainComponent={<Vehicles />} />} />
          <Route path={`${_ROUTES.VEHICLES}/:id`} element={<Layout mainComponent={<Vehicle />} />} />
          <Route path={_ROUTES.CHECKLIST_ITEMS} element={<Layout mainComponent={<ChecklistItems />} />} />
          <Route path={_ROUTES.BEAUTY_SHOTS} element={<Layout mainComponent={<BeautyShots />} />} />
          <Route path={_ROUTES.ASSIGNMENTS} element={<Layout mainComponent={<Assignments />} />} />
          <Route path={`${_ROUTES.ASSIGNMENTS}/:id`} element={<Layout mainComponent={<Assignment />} />} />
          <Route path={_ROUTES.INCIDENCES} element={<Layout mainComponent={<Incidences />} />} />
          <Route path={`${_ROUTES.INCIDENCES}/:id`} element={<Layout mainComponent={<Incidence />} />} />
          <Route path="/*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
