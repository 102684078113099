import { Store } from 'react-notifications-component';

export const addNotification = (message: string, type?: any, id?: string, duration?: number) => {
  if (duration === undefined) duration = 0;

  if (type === undefined) type = 'danger';

  if (type === 'danger') duration = 5000;

  Store.addNotification({
    message,
    type: type,
    insert: 'top',
    id: id,
    container: 'bottom-center',
    dismiss: {
      duration: duration,
      showIcon: true,
    },
  });
};

export const removeNotification = (id: string) => {
  window.setTimeout(() => {
    Store.removeNotification(id);
  }, 500);
};
