import React from 'react';
import { Box, Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _VALUES } from '../../../resources/constants/values';
import { MainWrapperComponent, SelectField, TextFieldComponent, TextSimpleComponent } from 'techsbcn-storybook';
import { debounce } from 'lodash';
import { IncidenceBase } from '../../../interfaces';
import { EnumToSelect, getEnumKeyByEnumValue, SelectEnum } from '../../../helpers';
import { BooleanEnum } from '../../../enums';
import { Link } from 'react-router-dom';
import { _ROUTES } from '../../../resources/constants/routes';

interface BasicIncidenceInfoFormProps {
  action: (data: any) => void;
  readModel?: IncidenceBase;
  loading: boolean;
  loadingUpdate: boolean;
}

const BasicIncidenceInfoForm: React.FC<BasicIncidenceInfoFormProps> = (props) => {
  const BasicInfoSchema = yup.object().shape({
    active: yup.string(),
    description: yup.string(),
  });
  const { getValues, control } = useForm({
    resolver: yupResolver(BasicInfoSchema),
  });

  const onSubmit = (data: any) => {
    props.action(data);
  };

  const setUpdate = debounce(() => {
    onSubmit(getValues());
  }, 1000);

  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.BASIC_INFO,
      }}
      loadingChildren={props.loading}
      loadingProgressBar={props.loadingUpdate}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={2}>
          <TextSimpleComponent fullWidth noBorders={false} label={_VALUES.ID} value={props.readModel?.id} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextSimpleComponent
            fullWidth
            className="text-underline"
            label={_VALUES.DRIVER}
            value={
              props.readModel?.createdBy && (
                <Link to={`${_ROUTES.DRIVERS}/${props.readModel?.createdBy?.id}`}>
                  <Box fontWeight="bold">{`${props.readModel?.createdBy?.name}`}</Box>
                </Link>
              )
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextSimpleComponent
            fullWidth
            className="text-underline"
            label={_VALUES.VAN}
            value={
              props.readModel?.vehicle && (
                <Link to={`${_ROUTES.VEHICLES}/${props.readModel?.vehicle?.id}`}>
                  <Box fontWeight="bold">{`${props.readModel?.vehicle?.name}`}</Box>
                </Link>
              )
            }
          ></TextSimpleComponent>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextSimpleComponent
            fullWidth
            label={_VALUES.DATE}
            value={
              <Box fontWeight="bold">
                {props.readModel?.createdOn && new Date(props.readModel.createdOn).toLocaleString('es-ES')}
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            control={control}
            defaultValue={
              props.readModel && props.readModel?.active !== null && props.readModel?.active !== undefined
                ? Boolean(JSON.parse(getEnumKeyByEnumValue(BooleanEnum, props.readModel.active)))
                : undefined
            }
            render={({ field: { onChange, value, name, ref } }) => {
              return (
                <SelectField
                  name={name}
                  inputRef={ref}
                  label={_VALUES.STATUS}
                  placeholder={`${_VALUES.STATUS}...`}
                  options={EnumToSelect(BooleanEnum, 'OPEN')}
                  defaultOptions={
                    value !== undefined && value !== null
                      ? SelectEnum(
                          BooleanEnum,
                          [BooleanEnum[value ? BooleanEnum.true : BooleanEnum.false].toString()],
                          'OPEN'
                        )
                      : undefined
                  }
                  onChange={(e) => {
                    onChange(Boolean(JSON.parse(e.toString())));
                    setUpdate();
                  }}
                  isClearable={false}
                />
              );
            }}
            name={'active'}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            defaultValue={props.readModel?.description}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.DESCRIPTION}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
                multiline={true}
              />
            )}
            name={'description'}
          />
        </Grid>
      </Grid>
    </MainWrapperComponent>
  );
};

export default BasicIncidenceInfoForm;
