import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Icon } from '@mui/material';
import { Search } from '@mui/icons-material';
import {
  MainWrapperComponent,
  TableComponent,
  TextFieldComponent,
  SelectAsyncField,
  SelectField,
} from 'techsbcn-storybook';
import { _VALUES } from '../../resources/constants/values';
import { IncidenceListRequest, SearchFilters, UserListRequest } from '../../interfaces';
import { useFetchIncidencesQuery } from '../../redux/incidences/incidencesSlice';
import { _ROUTES } from '../../resources/constants/routes';
import { vehicleListSimple } from '../../redux/vehicles/vehiclesAPI';
import { usersListSimple } from '../../redux/users/usersAPI';
import { Scope } from '../../enums/Scope';
import { BooleanEnum } from '../../enums';
import { EnumToSelect } from '../../helpers';

export const Incidences: React.FC = () => {
  const defaultFilters: IncidenceListRequest = {
    offset: 0,
    limit: 100,
    orderBy: 'id',
    orderAsc: true,
    page: 1,
  };

  const defaultDriversSelectFilters: UserListRequest = {
    offset: 0,
    limit: 100,
    orderBy: 'id',
    orderAsc: true,
    page: 1,
    roleIds: [Scope[Scope.DRIVER].toString()],
  };

  const navigate = useNavigate();

  const [filters, setFilters] = useState<IncidenceListRequest>(JSON.parse(JSON.stringify(defaultFilters)));
  const incidencesFetch = useFetchIncidencesQuery(filters);

  const setNewFilters = (name: string, value: any) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[name] = value;
    newFilters.page = 1;
    newFilters.offset = 0;
    setFilters(newFilters);
  };

  const applyTableFilters = (filtersTable: SearchFilters) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters.page = filtersTable.page;
    newFilters.limit = filtersTable.limit;
    newFilters.orderBy = filtersTable.orderBy;
    newFilters.orderAsc = filtersTable.orderAsc;
    newFilters.offset = filtersTable.offset;
    setFilters(newFilters);
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[event.target.name] = event.target.value;
    newFilters.page = 1;
    newFilters.offset = 0;
    setFilters(newFilters);
  };

  const onHandleSelectChange = (value: any, name: string) => {
    const checkedValue = value == null ? [] : value;
    setNewFilters(name, checkedValue);
  };
  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: _VALUES.INCIDENCES,
          searchBar: (
            <TextFieldComponent
              name="query"
              placeholder={`${_VALUES.SEARCH}...`}
              onChange={onHandleChange}
              startIcon={
                <Icon>
                  <Search />
                </Icon>
              }
            />
          ),
          filters: [
            {
              singleFilter: (
                <SelectAsyncField
                  label={_VALUES.VAN}
                  placeholder={`${_VALUES.VAN}...`}
                  name={'vehicleIds'}
                  searchPromise={vehicleListSimple}
                  onChange={(value, name) => onHandleSelectChange(value, name)}
                  isClearable={true}
                  isMulti={true}
                />
              ),
            },
            {
              singleFilter: (
                <SelectAsyncField
                  label={_VALUES.DRIVER}
                  placeholder={`${_VALUES.DRIVER}...`}
                  name={'driverIds'}
                  filters={() => {
                    const newFilters = JSON.parse(JSON.stringify(defaultDriversSelectFilters));
                    return newFilters;
                  }}
                  searchPromise={usersListSimple}
                  onChange={(value, name) => onHandleSelectChange(value, name)}
                  isClearable={true}
                  isMulti={true}
                />
              ),
            },
            {
              singleFilter: (
                <TextFieldComponent
                  name="createdOn"
                  label={_VALUES.DATE}
                  placeholder={`${_VALUES.DATE}...`}
                  onChange={onHandleChange}
                  type="date"
                  startIcon={
                    <Icon>
                      <Search />
                    </Icon>
                  }
                />
              ),
            },
            {
              singleFilter: (
                <SelectField
                  name="active"
                  label={_VALUES.STATUS}
                  placeholder={`${_VALUES.STATUS}...`}
                  options={EnumToSelect(BooleanEnum, 'OPEN')}
                  onChange={(value: any) => setNewFilters('active', value)}
                />
              ),
            },
          ],
        }}
      >
        <TableComponent
          rows={incidencesFetch.data && incidencesFetch.data.items.length > 0 ? incidencesFetch.data.items : []}
          columns={[
            { id: 'id', label: _VALUES.ID, minWidth: 20 },
            { id: 'vehicle.name', label: _VALUES.VAN, minWidth: 100 },
            { id: 'createdBy.name', label: _VALUES.DRIVER, minWidth: 100 },
            { id: 'createdOn', label: _VALUES.DATE, minWidth: 100, isDate: true },
            {
              id: 'active',
              label: _VALUES.STATUS,
              minWidth: 100,
              isBoolean: { enumType: BooleanEnum, enumSuffix: 'OPEN' },
            },
            { id: 'description', label: _VALUES.DESCRIPTION, minWidth: 100 },
          ]}
          values={_VALUES}
          totalCount={incidencesFetch.data ? incidencesFetch.data.totalCount : 0}
          baseFilters={filters}
          onFiltersChange={(filters: SearchFilters) => applyTableFilters(filters)}
          loading={incidencesFetch.isFetching}
          onClickRow={(id: number) => navigate(`${_ROUTES.INCIDENCES}/${id}`)}
        />
      </MainWrapperComponent>
    </Box>
  );
};
