import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Icon } from '@mui/material';
import { Search } from '@mui/icons-material';
import {
  ButtonComponent,
  MainWrapperComponent,
  TableComponent,
  TextFieldComponent,
  SelectAsyncField,
} from 'techsbcn-storybook';
import { _VALUES } from '../../resources/constants/values';
import { CompanyListRequest, SearchFilters } from '../../interfaces';
import { useFetchCompaniesQuery } from '../../redux/companies/companiesSlice';
import { _ROUTES } from '../../resources/constants/routes';
import plansListSimple from '../../redux/plans/plansAPI';
import { CompanyCreateModal } from '../../components/modals';

export const Companies: React.FC = () => {
  const defaultFilters: CompanyListRequest = {
    offset: 0,
    limit: 100,
    orderBy: 'id',
    orderAsc: true,
    page: 1,
  };

  const navigate = useNavigate();
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [filters, setFilters] = useState<CompanyListRequest>(JSON.parse(JSON.stringify(defaultFilters)));
  const companyFetch = useFetchCompaniesQuery(filters);

  const setNewFilters = (name: string, value: any) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[name] = value;
    newFilters.page = 1;
    newFilters.offset = 0;
    setFilters(newFilters);
  };

  const applyTableFilters = (filtersTable: SearchFilters) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters.page = filtersTable.page;
    newFilters.limit = filtersTable.limit;
    newFilters.orderBy = filtersTable.orderBy;
    newFilters.orderAsc = filtersTable.orderAsc;
    newFilters.offset = filtersTable.offset;
    setFilters(newFilters);
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFilters(event.target.name, event.target.value);
  };

  const onHandleSelectChange = (value: number, name: string) => {
    const checkedValue = value == null ? [] : value;
    setNewFilters(name, checkedValue);
  };

  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: _VALUES.COMPANIES,
          searchBar: (
            <TextFieldComponent
              name="query"
              placeholder={`${_VALUES.SEARCH}...`}
              onChange={onHandleChange}
              startIcon={
                <Icon>
                  <Search />
                </Icon>
              }
            />
          ),
          filters: [
            {
              singleFilter: (
                <SelectAsyncField
                  label={_VALUES.PLAN}
                  placeholder={_VALUES.PLAN}
                  name={'planIds'}
                  searchPromise={plansListSimple}
                  onChange={onHandleSelectChange}
                  isClearable={true}
                  isMulti={true}
                />
              ),
            },
          ],
          actions: [
            {
              children: (
                <ButtonComponent
                  label={_VALUES.CREATE_COMPANY}
                  onClick={() => setOpenCompanyModal(!openCompanyModal)}
                />
              ),
            },
          ],
        }}
      >
        <TableComponent
          rows={companyFetch.data && companyFetch.data.items.length > 0 ? companyFetch.data.items : []}
          columns={[
            { id: 'id', label: _VALUES.ID, minWidth: 20 },
            { id: 'name', label: _VALUES.NAME, minWidth: 100 },
            { id: 'plan.name', label: _VALUES.PLAN, minWidth: 100 },
            { id: 'administratorsCount', label: `#${_VALUES.ADMINISTRATORS}`, minWidth: 100 },
            { id: 'driversCount', label: `#${_VALUES.DRIVERS}`, minWidth: 100 },
          ]}
          values={_VALUES}
          totalCount={companyFetch.data ? companyFetch.data.totalCount : 0}
          baseFilters={filters}
          onFiltersChange={(filters: SearchFilters) => applyTableFilters(filters)}
          loading={companyFetch.isFetching}
          onClickRow={(id: number) => navigate(`${_ROUTES.COMPANIES}/${id}`)}
        />
      </MainWrapperComponent>
      <CompanyCreateModal
        modalOpen={(open: boolean) => {
          setOpenCompanyModal(open);
        }}
        openModal={openCompanyModal}
      />
    </Box>
  );
};
