export interface Contact {
  name: string;
  phone: string;
  email: string;
}

export const ContactDefaults: Contact = {
  name: '',
  phone: '',
  email: '',
};
