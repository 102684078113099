import { AddressDefaults, Address } from './Address';
import { ContactDefaults, Contact } from './Contact';
import { BasicEntity } from '../shared/BasicEntity';

export interface CompanyBase {
  id?: number;
  name: string;
  fiscalName: string;
  fiscalId: string;
  plan?: BasicEntity;
  address?: Address;
  contact: Contact;
  createdOn?: Date;
  active?: string;
}

export const CompanyBaseDefaults: CompanyBase = {
  name: '',
  fiscalName: '',
  fiscalId: '',
  address: AddressDefaults,
  contact: ContactDefaults,
};
