import React from 'react';
import { Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _VALUES } from '../../../resources/constants/values';
import { MainWrapperComponent, TextFieldComponent } from 'techsbcn-storybook';
import { debounce } from 'lodash';
import { CompanyBase } from '../../../interfaces';

interface FiscalCompanyInfoFormProps {
  action: (data: any) => void;
  readModel?: CompanyBase;
  loading: boolean;
  loadingUpdate: boolean;
}

const FiscalCompanyInfoForm: React.FC<FiscalCompanyInfoFormProps> = (props) => {
  const FiscalInfoSchema = yup.object().shape({
    fiscalId: yup.string(),
    fiscalName: yup.string(),
    address: yup.object().shape({
      street: yup.string(),
      postalCode: yup.string(),
      city: yup.string(),
      region: yup.string(),
      country: yup.string(),
    }),
  });

  const { getValues, control } = useForm({
    resolver: yupResolver(FiscalInfoSchema),
  });

  const onSubmit = (data: any) => {
    props.action(data);
  };

  const setUpdate = debounce(() => {
    onSubmit(getValues());
  }, 1000);

  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.FISCAL_INFO,
      }}
      loadingChildren={props.loading}
      loadingProgressBar={props.loadingUpdate}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            defaultValue={props.readModel?.fiscalId}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.FISCAL_ID}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'fiscalId'}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            control={control}
            defaultValue={props.readModel?.fiscalName}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.FISCAL_NAME}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'fiscalName'}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            defaultValue={props.readModel?.address?.street}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.ADDRESS}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'address[street]'}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            control={control}
            defaultValue={props.readModel?.address?.postalCode}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.POSTAL_CODE}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'address[postalCode]'}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            control={control}
            defaultValue={props.readModel?.address?.city}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.CITY}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'address[city]'}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            control={control}
            defaultValue={props.readModel?.address?.region}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.REGION}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'address[region]'}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            control={control}
            defaultValue={props.readModel?.address?.country}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.COUNTRY}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'address[country]'}
          />
        </Grid>
      </Grid>
    </MainWrapperComponent>
  );
};

export default FiscalCompanyInfoForm;
