import { AuthHeader, BasicHeader, ErrorHandler, ResponseHandler, SaveToken } from '../../helpers';
import { _ROUTES } from '../../resources/constants/routes';
import { AuthResult, AuthRequest, PasswordRecoveryRequest, ResetPasswordRequest } from '../../interfaces';

export const login = async (request: AuthRequest) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: BasicHeader(),
    body: JSON.stringify(request),
  };

  return new Promise<AuthResult>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.AUTH}`, requestOptions)
      .then(ResponseHandler)
      .then((result: AuthResult) => {
        SaveToken(result.token);
        resolve(result);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const refreshToken = async () => {
  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: AuthHeader(),
  };

  return new Promise<{ token: string }>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.AUTH}`, requestOptions)
      .then(ResponseHandler)
      .then((token: string) => {
        SaveToken(token);
        resolve({ token });
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const resetPassword = async (request: PasswordRecoveryRequest) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: BasicHeader(),
    body: JSON.stringify(request),
  };

  return new Promise<{ result: boolean }>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.AUTH}${_ROUTES.RECOVERY_TOKENS}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve({ result: true });
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const resetAccount = async (request: ResetPasswordRequest) => {
  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: BasicHeader(),
    body: JSON.stringify(request),
  };
  return new Promise<void>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.AUTH}${_ROUTES.CREDENTIALS}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};
