export const downloadFileBlob = (binaryString: any) => {
  const data = window.URL.createObjectURL(new Blob([binaryString.blob]));
  const fileName = binaryString.contentDisposition.split('filename=')[1];
  redirectDownload(data, fileName);
};

export const redirectDownload = (url: any, fileName: string) => {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  a.style.visibility = 'hidden';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getImageByBase64 = (content: string) => {
  const binaryString = window.atob(content);
  const blob = new Uint8Array(binaryString.length).map((_byte, i) => binaryString.charCodeAt(i));
  return URL.createObjectURL(new Blob([blob])).toString();
};

export const getImageByBlob = (binaryString: any) => {
  return URL.createObjectURL(new Blob([binaryString.blob])).toString();
};

export const toBase64 = (file: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => reader.result && resolve(reader.result.toString());
    reader.onerror = (error) => reject(error);
  });
};
