import { _ROUTES } from '../../resources/constants/routes';
import { apiSlice } from '../apiSlice';
import queryString from 'query-string';
import { ErrorHandler } from '../../helpers';
import { BeautyShotListRequest } from '../../interfaces/beautyShots/BeautyShotListRequest';
import { BeautyShotListItem, CreateBeautyShot, UpdateBeautyShot } from '../../interfaces';
import { ReduxStatus } from '../../enums';
import { createBeautyShot, removeBeautyShot, updateBeautyShot } from './beautyShotsAPI';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const beautyShotsEndpoints = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchBeautyShots: builder.query<BeautyShotListItem[], BeautyShotListRequest>({
        keepUnusedDataFor: 0,
        query: (request) => `${_ROUTES.BEAUTY_SHOTS}/?${queryString.stringify(request)}`,
        async onQueryStarted(_request, { queryFulfilled }) {
          await queryFulfilled.catch((err) => {
            ErrorHandler(err);
          });
        },
      }),
    };
  },
});
export const { useFetchBeautyShotsQuery } = beautyShotsEndpoints;

interface BeautyShotCrudState {
  status: ReduxStatus;
}

export interface ChecklistItemState {
  crudBeautyShot: BeautyShotCrudState;
}

const initialStateBeautyShots: ChecklistItemState = {
  crudBeautyShot: { status: ReduxStatus.Idle },
};

export const beautyShotCreate = createAsyncThunk<number, CreateBeautyShot>(
  `${_ROUTES.BEAUTY_SHOTS}/CREATE`,
  async (request: CreateBeautyShot, { rejectWithValue }) => {
    return await createBeautyShot(request).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const beautyShotRemove = createAsyncThunk<void, number>(
  `${_ROUTES.BEAUTY_SHOTS}/DELETE`,
  async (request: number, { rejectWithValue }) => {
    return await removeBeautyShot(request).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const beautyShotUpdate = createAsyncThunk<void, { beautyShotId: number; beautyShot: UpdateBeautyShot }>(
  `${_ROUTES.BEAUTY_SHOTS}/UPDATE`,
  async (request: { beautyShotId: number; beautyShot: UpdateBeautyShot }, { rejectWithValue }) => {
    return await updateBeautyShot(request.beautyShotId, request.beautyShot).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const beautyShotsSlice = createSlice({
  name: 'beautyShots',
  initialState: initialStateBeautyShots,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(beautyShotCreate.pending, (state) => {
        state.crudBeautyShot.status = ReduxStatus.Loading;
      })
      .addCase(beautyShotCreate.rejected, (state) => {
        state.crudBeautyShot.status = ReduxStatus.Failed;
      })
      .addCase(beautyShotCreate.fulfilled, (state) => {
        state.crudBeautyShot.status = ReduxStatus.Succeeded;
      })

      .addCase(beautyShotRemove.pending, (state) => {
        state.crudBeautyShot.status = ReduxStatus.Loading;
      })
      .addCase(beautyShotRemove.rejected, (state) => {
        state.crudBeautyShot.status = ReduxStatus.Failed;
      })
      .addCase(beautyShotRemove.fulfilled, (state) => {
        state.crudBeautyShot.status = ReduxStatus.Succeeded;
      })

      .addCase(beautyShotUpdate.pending, (state) => {
        state.crudBeautyShot.status = ReduxStatus.Loading;
      })
      .addCase(beautyShotUpdate.rejected, (state) => {
        state.crudBeautyShot.status = ReduxStatus.Failed;
      })
      .addCase(beautyShotUpdate.fulfilled, (state) => {
        state.crudBeautyShot.status = ReduxStatus.Succeeded;
      });
  },
});

export const getBeautyShotsState = (state: RootState) => state.beautyShots;

export default beautyShotsSlice.reducer;
