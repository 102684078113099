import React from 'react';
import { ModalComponent } from 'techsbcn-storybook';
import { _VALUES } from '../../../resources/constants/values';
import { userCreate, userUpdate, getUserState } from '../../../redux/users/usersSlice';
import { _ROUTES } from '../../../resources/constants/routes';
import { useAppDispatch, useAppSelector } from '../../../helpers';
import { Scope } from '../../../enums/Scope';
import { UserBase } from '../../../interfaces';
import { useNavigate } from 'react-router-dom';
import UserForm from '../../users/forms/UserForm';
import { ReduxStatus } from '../../../enums';

interface UserModalProps {
  modalOpen: (open: boolean) => void;
  openModal: boolean;
  userModel?: UserBase;
  companyId?: number;
  role: Scope;
}

export const UserModal: React.FC<UserModalProps> = (props) => {
  const userCrud = useAppSelector(getUserState).userCrudState;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    if (props.userModel && props.userModel?.id) {
      data = Object.assign({ active: true }, data);
      dispatch(userUpdate({ userId: props.userModel.id, user: data })).finally(() => {
        props.modalOpen(false);
      });
    } else {
      if (props.companyId != null) {
        data = Object.assign({ companyId: props.companyId }, data);
      }
      dispatch(userCreate(data))
        .then((response) => {
          const id = response.payload;
          if (props.role == Scope.DRIVER) navigate(`${_ROUTES.DRIVERS}/${id}`);
        })
        .finally(() => {
          props.modalOpen(false);
        });
    }
  };

  return (
    <ModalComponent
      title={
        (props.userModel && _VALUES.EDIT_ADMIN) ||
        (props.role == Scope.ADMIN && _VALUES.CREATE_ADMIN) ||
        (props.role == Scope.DRIVER && _VALUES.CREATE_DRIVER)
      }
      setShow={props.openModal}
      onHide={() => props.modalOpen(false)}
      cancelButton={{ label: _VALUES.CANCEL }}
      submitButton={{
        label: _VALUES.CONFIRM,
        form: 'user-form',
        type: 'submit',
      }}
      loading={userCrud.status === ReduxStatus.Loading}
    >
      <UserForm action={(data) => onSubmit(data)} userModel={props.userModel} role={props.role} />
    </ModalComponent>
  );
};
