import { AuthHeader, ErrorHandler, ResponseHandler } from '../../helpers';
import { _ROUTES } from '../../resources/constants/routes';
import { IncidenceBase, UpdateIncidence } from '../../interfaces';

export const readIncidence = async (vehicleId: number) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: AuthHeader(),
  };

  return new Promise<IncidenceBase>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.INCIDENCES}/${vehicleId}`, requestOptions)
      .then(ResponseHandler)
      .then((result: IncidenceBase) => {
        resolve(result);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const updateIncidence = async (incidence: UpdateIncidence, id: string) => {
  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: AuthHeader(),
    body: JSON.stringify(incidence),
  };

  return new Promise<void>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.INCIDENCES}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};
