import jwt_decode from 'jwt-decode';
import { ItemsNav } from '../resources/constants/routeConfig';
import { matchPath } from 'react-router-dom';

const tokenName = process.env.REACT_APP_TOKEN_NAME as string;

export const SaveToken = (userToken: string) => {
  localStorage.setItem(tokenName, JSON.stringify(userToken));
};
export const GetToken = () => {
  return localStorage.getItem(tokenName) ? JSON.parse(localStorage.getItem(tokenName) || '') : null;
};

export const GetUserIdToken = () => {
  try {
    const decoded: any = jwt_decode(GetToken());
    const id: string = Object.keys(decoded).find((key) => key.includes('name')) || '';
    return decoded[id];
  } catch {
    return null;
  }
};

export const RemoveToken = () => {
  localStorage.removeItem(tokenName);
};

export const GetUserRoles = () => {
  try {
    const decoded: any = jwt_decode(GetToken());
    const roleKey: string = Object.keys(decoded).find((key) => key.includes('role')) || '';
    return decoded[roleKey];
  } catch {
    return [];
  }
};

const getRoutePermisions = (items: any[], pathname: string) => {
  let navItem: any = undefined;
  items.find((item: any) => {
    if (item.persmisionAvailable) {
      if (item.children) {
        for (let i = 0; i < item.children.length; i++) {
          if (matchPath(`${item.children[i].route}/*`, pathname)) {
            navItem = item;
          }
        }
      } else if (item.route) {
        if (matchPath(`${item.route}/*`, pathname)) {
          navItem = item;
        }
      }
    } else if (item.children) {
      for (let i = 0; i < item.children.length; i++) {
        if (matchPath(`${item.children[i].route}/*`, pathname)) {
          navItem = item.children[i];
        }
      }
    } else {
      if (matchPath(`${item.route}/*`, pathname)) {
        navItem = item;
      }
    }
  });
  return navItem;
};

export const checkPermission = (pathname: string, userRoles?: any) => {
  const item: any = getRoutePermisions(ItemsNav, pathname);
  return checkItemPermission(item?.persmisionAvailable, [userRoles]);
};

const checkItemPermission = (persmisionAvailable?: any, userRoles?: any) => {
  if (persmisionAvailable === null || persmisionAvailable === undefined || persmisionAvailable.length === 0) {
    return true;
  }

  if (userRoles && Array.isArray(userRoles) && Array.isArray(persmisionAvailable)) {
    return persmisionAvailable.some((r) => userRoles.indexOf(r) >= 0);
  }

  return userRoles.indexOf(persmisionAvailable) >= 0;
};

export const getRoutePermision = (userRoles: string[]) => {
  let navItem: any = undefined;
  JSON.parse(JSON.stringify(ItemsNav))
    .reverse()
    .find((item: any) => {
      if (item.persmisionAvailable) {
        if (item.children) {
          for (let i = 0; i < item.children.length; i++) {
            if (checkItemPermission(item?.persmisionAvailable, userRoles)) {
              navItem = item.route;
            }
          }
        } else if (item.route) {
          if (checkItemPermission(item?.persmisionAvailable, userRoles)) {
            navItem = item.route;
          }
        }
      } else if (item.children) {
        for (let i = 0; i < item.children.length; i++) {
          if (checkItemPermission(item?.persmisionAvailable, userRoles)) {
            navItem = item.children[i].route;
          }
        }
      } else {
        if (checkItemPermission(item?.persmisionAvailable, userRoles)) {
          navItem = item.route;
        }
      }
    });
  return navItem;
};
