export const BasicHeader = () => {
  return { 'Content-Type': 'application/json' };
};

const tokenName = process.env.REACT_APP_TOKEN_NAME as string;

export const AuthHeader = () => {
  const token = localStorage.getItem(tokenName) ? JSON.parse(localStorage.getItem(tokenName) || '') : null;
  if (token) {
    return { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
  } else {
    return BasicHeader();
  }
};
