import { AuthHeader, ErrorHandler, ResponseHandler } from '../../helpers';
import {
  ListResultCollection,
  SimpleSearchResult,
  UserBase,
  CreateUser,
  UpdateUser,
  UserListRequest,
} from '../../interfaces';
import { _ROUTES } from '../../resources/constants/routes';
import queryString from 'query-string';

export const usersListSimple = async (request: UserListRequest) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: AuthHeader(),
  };

  return new Promise<ListResultCollection<SimpleSearchResult>>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.USERS}/?${queryString.stringify(request)}`, requestOptions)
      .then(ResponseHandler)
      .then((result: any) => {
        const trans = Object.assign({}, result);
        trans.items = result.items.map((item: any) => {
          const temp = Object.assign({}, item);
          temp.name = `${item.name} ${item.surnames}`;
          return temp;
        });
        resolve(trans);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const userRead = async (id: string) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: AuthHeader(),
  };

  return new Promise<UserBase>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.USERS}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then((result: UserBase) => {
        resolve(result);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const createUser = async (user: CreateUser) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: AuthHeader(),
    body: JSON.stringify(user),
  };

  return new Promise<number>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.USERS}`, requestOptions)
      .then(ResponseHandler)
      .then((userId: number) => {
        resolve(userId);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const updateUser = async (id: number, user: UpdateUser) => {
  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: AuthHeader(),
    body: JSON.stringify(user),
  };

  return new Promise<void>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.USERS}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const removeUser = async (id: number) => {
  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: AuthHeader(),
  };

  return new Promise<void>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.USERS}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};
