import { _ROUTES } from '../../resources/constants/routes';
import { CompanyListItem, CompanyListRequest, ListResultCollection } from '../../interfaces';
import { apiSlice } from '../apiSlice';
import queryString from 'query-string';
import { ErrorHandler } from '../../helpers';

const companiesEndpoints = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchCompanies: builder.query<ListResultCollection<CompanyListItem>, CompanyListRequest>({
        keepUnusedDataFor: 0,
        query: (request) => `${_ROUTES.COMPANIES}/?${queryString.stringify(request)}`,
        async onQueryStarted(_request, { queryFulfilled }) {
          await queryFulfilled.catch((err) => {
            ErrorHandler(err);
          });
        },
      }),
    };
  },
});

export const { useFetchCompaniesQuery } = companiesEndpoints;
