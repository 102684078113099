import React, { useState } from 'react';
import { PublicFormWrapper, ChangePasswordForm } from 'techsbcn-storybook';
import furgoCheckLogo from '../../content/img/furgoCheck.png';
import { _VALUES } from '../../resources/constants/values';
import { _ROUTES } from '../../resources/constants/routes';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ResetPasswordRequest } from '../../interfaces';
import { resetAccount } from '../../redux/auth/authAPI';
import TechsbcnPoweredComponent from '../../components/shared/TechsbcnPoweredComponent';
import { Box, Typography } from '@mui/material';

export const ResetAccount: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { guid } = useParams() as {
    guid: string;
  };
  const navigate = useNavigate();

  const buildCredentials = (data: any): ResetPasswordRequest => {
    const credentials: ResetPasswordRequest = {
      token: guid,
      newPassword: data.password,
    };

    return credentials;
  };

  const onSubmit = (data: ResetPasswordRequest) => {
    setLoading(true);
    resetAccount(data)
      .then(() => {
        setLoading(false);
        navigate(_ROUTES.LOGIN);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <PublicFormWrapper>
      <ChangePasswordForm
        topComponent={
          <Box textAlign="center">
            <img src={furgoCheckLogo} className="login-logo" alt="logo" />
          </Box>
        }
        onClick={(data: any) => onSubmit(buildCredentials(data))}
        loading={loading}
        requiredText={{
          requiredTextPassword: _VALUES.REQUIRED.REQUIRED_PASSWORD,
          requiredTextSamePassword: _VALUES.REQUIRED.REQUIRED_SAME_PASSWORD,
          requiredPasswordPolices: _VALUES.REQUIRED.PASSWORD_POLICIES,
        }}
        valuesText={{
          buttonText: _VALUES.RESET_PASSWORD,
          labelPassword: _VALUES.NEW_PASSWORD,
          placeholderPassword: _VALUES.NEW_PASSWORD,
          labelSamePassword: _VALUES.REPEAT_PASSWORD,
          placeholderSamePassword: _VALUES.REPEAT_PASSWORD,
        }}
        bottomComponent={
          <Box textAlign="center">
            <Typography gutterBottom component="label" className="text-underline cursor-pointer">
              <Link to={_ROUTES.LOGIN}>{_VALUES.GO_BACK}</Link>
            </Typography>
            <Box pt={3}>
              <TechsbcnPoweredComponent />
            </Box>
          </Box>
        }
      />
    </PublicFormWrapper>
  );
};
