import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice';
import authReducer from './auth/authSlice';
import usersReducer from './users/usersSlice';
import vehiclesReducer from './vehicles/vehicleSlice';
import assignmentsReducer from './assignments/assignmentsSlice';
import checklistItemsReducer from './checklistItems/checklistItemsSlice';
import beautyShotsReducer from './beautyShots/beautyShotsSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    vehicles: vehiclesReducer,
    assignments: assignmentsReducer,
    checklistItems: checklistItemsReducer,
    beautyShots: beautyShotsReducer,

    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(apiSlice.middleware);
  },
});

export const getState = (state: RootState) => state;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
