import React from 'react';
import { Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _VALUES } from '../../../resources/constants/values';
import { MainWrapperComponent, TextFieldComponent, SelectField, TextSimpleComponent } from 'techsbcn-storybook';
import * as _ from 'lodash';
import { VehicleBase } from '../../../interfaces';
import { EnumToSelect, getEnumKeyByEnumValue, SelectEnum } from '../../../helpers';
import { BooleanEnum } from '../../../enums/BooleanEnum';

interface BasicVehicleInfoFormProps {
  action: (data: any) => void;
  readModel?: VehicleBase;
  loading: boolean;
  loadingUpdate: boolean;
}

const BasicVehicleInfoForm: React.FC<BasicVehicleInfoFormProps> = (props) => {
  const BasicInfoSchema = yup.object().shape({
    active: yup.string(),
    manufacturer: yup.string(),
    model: yup.string(),
    registrationNumber: yup.string(),
    provider: yup.string(),
  });
  const { getValues, control } = useForm({
    resolver: yupResolver(BasicInfoSchema),
  });

  const onSubmit = (data: any) => {
    props.action(data);
  };

  const setUpdate = _.debounce(() => {
    onSubmit(getValues());
  }, 1000);

  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.BASIC_INFO,
      }}
      loadingChildren={props.loading}
      loadingProgressBar={props.loadingUpdate}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={2}>
          <TextSimpleComponent fullWidth noBorders={false} label={_VALUES.ID} value={props.readModel?.id} />
        </Grid>
        <Grid item xs={12} md={5}>
          <Controller
            control={control}
            defaultValue={props.readModel?.manufacturer}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.MANUFACTURER}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'manufacturer'}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Controller
            control={control}
            defaultValue={props.readModel?.model}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.MODEL}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'model'}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            control={control}
            defaultValue={
              props.readModel && props.readModel?.active !== null && props.readModel?.active !== undefined
                ? Boolean(JSON.parse(getEnumKeyByEnumValue(BooleanEnum, props.readModel.active)))
                : undefined
            }
            render={({ field: { onChange, value, name, ref } }) => {
              return (
                <SelectField
                  name={name}
                  inputRef={ref}
                  label={_VALUES.STATUS}
                  placeholder={`${_VALUES.STATUS}...`}
                  options={EnumToSelect(BooleanEnum, 'ACTIVE')}
                  defaultOptions={
                    value !== undefined && value !== null
                      ? SelectEnum(
                          BooleanEnum,
                          [BooleanEnum[value ? BooleanEnum.true : BooleanEnum.false].toString()],
                          'ACTIVE'
                        )
                      : undefined
                  }
                  onChange={(e) => {
                    onChange(Boolean(JSON.parse(e.toString())));
                    setUpdate();
                  }}
                  isClearable={false}
                />
              );
            }}
            name={'active'}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Controller
            control={control}
            defaultValue={props.readModel?.registrationNumber}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.REGISTRATIONNUMBER}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'registrationNumber'}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Controller
            control={control}
            defaultValue={props.readModel?.provider}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.PROVIDER}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'provider'}
          />
        </Grid>
      </Grid>
    </MainWrapperComponent>
  );
};

export default BasicVehicleInfoForm;
