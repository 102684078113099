import { _ROUTES } from '../../resources/constants/routes';
import { ListResultCollection } from '../../interfaces';
import { apiSlice } from '../apiSlice';
import queryString from 'query-string';
import { ErrorHandler } from '../../helpers';
import { IncidenceListItem } from '../../interfaces/incidence/IncidenceListItem';
import { IncidenceListRequest } from '../../interfaces/incidence/IncidenceListRequest';

const incidencesEndpoints = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchIncidences: builder.query<ListResultCollection<IncidenceListItem>, IncidenceListRequest>({
        keepUnusedDataFor: 0,
        query: (request) => `${_ROUTES.INCIDENCES}/?${queryString.stringify(request)}`,
        async onQueryStarted(_request, { queryFulfilled }) {
          await queryFulfilled.catch((err) => {
            ErrorHandler(err);
          });
        },
      }),
    };
  },
});

export const { useFetchIncidencesQuery } = incidencesEndpoints;
