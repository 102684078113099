export interface Address {
  street: string;
  postalCode: string;
  city: string;
  region: string;
  country: string;
}

export const AddressDefaults: Address = {
  street: '',
  postalCode: '',
  city: '',
  region: '',
  country: '',
};
