import React, { useState } from 'react';
import { Box, Typography } from '@mui/material/';
import { _VALUES } from '../../resources/constants/values';
import { resetPassword } from '../../redux/auth/authAPI';
import { addNotification } from '../../helpers/NotificationHelper';
import { Link } from 'react-router-dom';
import { _ROUTES } from '../../resources/constants/routes';
import furgoCheckLogo from '../../content/img/furgoCheck.png';
import { PublicFormWrapper, ResetPasswordForm } from 'techsbcn-storybook';
import { PasswordRecoveryRequest } from '../../interfaces';
import TechsbcnPoweredComponent from '../../components/shared/TechsbcnPoweredComponent';

export const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: any) => {
    setLoading(true);
    const request: PasswordRecoveryRequest = { email: data.email };
    resetPassword(request)
      .then(() => {
        addNotification(_VALUES.RESET_PASSWORD_SUCCESS, 'success', 'success_reset_password', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PublicFormWrapper>
      <ResetPasswordForm
        requiredText={{ requiredTextMail: _VALUES.REQUIRED.REQUIRED_MAIL }}
        valuesText={{ buttonText: _VALUES.ACCOUNT_RECOVERY, placeholder: _VALUES.EMAIL, label: _VALUES.EMAIL }}
        loading={loading}
        onClick={onSubmit}
        topComponent={
          <Box textAlign="center">
            <img src={furgoCheckLogo} alt="-" className="login-logo" />
            <Box mt={3}>
              <div>
                <Typography>{_VALUES.ENTER_ADDRESS}</Typography>
                <Typography>{_VALUES.EMAIL_SENDING_MESSAGE}</Typography>
              </div>
            </Box>
          </Box>
        }
        bottomComponent={
          <Box textAlign="center">
            <Typography gutterBottom component="label" className="text-underline cursor-pointer">
              <Link to={_ROUTES.LOGIN}>{_VALUES.GO_BACK}</Link>
            </Typography>
            <Box pt={3}>
              <TechsbcnPoweredComponent />
            </Box>
          </Box>
        }
      />
    </PublicFormWrapper>
  );
};
