import React from 'react';
import { Grid } from '@mui/material';
import { ModalComponent, SelectAsyncField, TextFieldComponent } from 'techsbcn-storybook';
import { _VALUES } from '../../../resources/constants/values';
import { useForm, Controller } from 'react-hook-form';
import { vehicleListSimple } from '../../../redux/vehicles/vehiclesAPI';
import { usersListSimple } from '../../../redux/users/usersAPI';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { create } from '../../../redux/assignments/assignmentsAPI';
import { _ROUTES } from '../../../resources/constants/routes';
import { useNavigate } from 'react-router-dom';
import { Scope } from '../../../enums';
import { UserListRequest } from '../../../interfaces';

interface AssignmetCreateModalProps {
  modalOpen: (open: boolean) => void;
  openModal: boolean;
}

export const AssignmentCreateModal: React.FC<AssignmetCreateModalProps> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const defaultDriverFilters: UserListRequest = {
    offset: 0,
    page: 1,
    orderBy: 'Name',
    limit: 20,
    orderAsc: true,
    roleIds: [Scope[Scope.DRIVER].toString()],
  };

  const AssignmentSchema = yup.object().shape({
    scheduledFor: yup.string().required(_VALUES.REQUIRED.REQUIRED_FIELD),
    vehicleId: yup.number().required(_VALUES.REQUIRED.REQUIRED_FIELD),
    driverId: yup.number().required(_VALUES.REQUIRED.REQUIRED_FIELD),
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AssignmentSchema),
  });

  const onSubmit = (data: any) => {
    setLoading(true);
    create(data)
      .then((id: number) => {
        props.modalOpen(false);
        setLoading(false);
        navigate(`${_ROUTES.ASSIGNMENTS}/${id}`);
      })
      .catch(() => {
        setLoading(false);
        props.modalOpen(false);
      });
  };

  return (
    <ModalComponent
      title={_VALUES.CREATE_ASSIGNMENT}
      setShow={props.openModal}
      onHide={() => {
        props.modalOpen(false);
        reset({
          name: '',
          mandatory: false,
        });
      }}
      cancelButton={{ label: _VALUES.CANCEL }}
      submitButton={{
        label: _VALUES.CONFIRM,
        form: 'assignment-create-form',
        type: 'submit',
      }}
      loading={loading}
    >
      <form id="assignment-create-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.DATE}
                  placeholder={`${_VALUES.DATE}...`}
                  type="datetime-local"
                  error={!!errors.scheduledFor}
                  helperText={errors.scheduledFor?.message}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  defaultValue={value}
                />
              )}
              name={'scheduledFor'}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <SelectAsyncField
                  label={_VALUES.VAN}
                  name={name}
                  placeholder={`${_VALUES.VAN}...`}
                  inputRef={ref}
                  invalid={!!errors.vehicleId}
                  helperText={errors.vehicleId?.message}
                  searchPromise={vehicleListSimple}
                  defaultOptions={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  isClearable={false}
                />
              )}
              name={'vehicleId'}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <SelectAsyncField
                  label={_VALUES.DRIVER}
                  name={name}
                  invalid={!!errors.driverId}
                  helperText={errors.driverId?.message}
                  placeholder={`${_VALUES.DRIVER}...`}
                  inputRef={ref}
                  filters={() => {
                    const newFilters = JSON.parse(JSON.stringify(defaultDriverFilters));
                    return newFilters;
                  }}
                  searchPromise={usersListSimple}
                  defaultOptions={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  isClearable={false}
                />
              )}
              name={'driverId'}
            />
          </Grid>
        </Grid>
      </form>
    </ModalComponent>
  );
};
