import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainWrapperComponent } from 'techsbcn-storybook';
import { UpdateIncidence, IncidenceBase, IncidenceBaseDefaults } from '../../interfaces';
import { readIncidence, updateIncidence } from '../../redux/incidences/incidencesAPI';
import { _VALUES } from '../../resources/constants/values';
import BasicIncidenceInfoForm from '../../components/incidences/forms/BasicIncidenceInfoForm';
import IncidencePhoto from '../../components/incidences/component/IncidencePhoto';
import * as _ from 'lodash';
import { deepOmit, isEmptyValues } from '../../helpers';

export const Incidence: React.FC = () => {
  const { id } = useParams() as {
    id: string;
  };

  const [incidence, setIncidence] = useState<IncidenceBase>();
  const [loadingIncidence, setLoadingIncidence] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  useEffect(() => {
    let isActive = true;

    setLoadingIncidence(true);
    readIncidence(parseInt(id))
      .then((result) => {
        if (isActive) {
          const incidenceMerge: any = _.merge(
            deepOmit(JSON.parse(JSON.stringify(IncidenceBaseDefaults)), _.isNil),
            deepOmit(JSON.parse(JSON.stringify(result)), _.isNil)
          );
          setIncidence(incidenceMerge);
          setLoadingIncidence(false);
        }
      })
      .catch(() => {
        setLoadingIncidence(false);
      });
    return () => {
      isActive = false;
    };
  }, [id]);

  const onSubmit = (data: UpdateIncidence) => {
    setLoadingUpdate(true);
    updateIncidence(
      Object.assign({} as UpdateIncidence, deepOmit(deepOmit(data, isEmptyValues), isEmptyValues)),
      id
    ).finally(() => {
      setLoadingUpdate(false);
    });
  };

  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: `${_VALUES.INCIDENCE} #${id}`,
        }}
        loading={loadingIncidence}
      />
      <Box my={2}>
        <BasicIncidenceInfoForm
          action={onSubmit}
          readModel={incidence}
          loading={loadingIncidence}
          loadingUpdate={loadingUpdate}
        />
      </Box>
      {incidence != null && <IncidencePhoto image={incidence.file} />}
    </Box>
  );
};
