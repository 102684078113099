import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { TextFieldComponent } from 'techsbcn-storybook';
import { _VALUES } from '../../../resources/constants/values';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _ROUTES } from '../../../resources/constants/routes';
import { UserBase } from '../../../interfaces';
import { Scope } from '../../../enums/Scope';

interface UserFormProps {
  userModel?: UserBase;
  action: (data: any) => void;
  role: Scope;
}

const UserForm: React.FC<UserFormProps> = (props) => {
  const UserSchema = yup.object().shape({
    name: yup.string().required(_VALUES.REQUIRED.REQUIRED_FIELD),
    surnames: yup.string().required(_VALUES.REQUIRED.REQUIRED_FIELD),
    phone: yup.string(),
    email: yup.string().required(_VALUES.REQUIRED.REQUIRED_FIELD).email(_VALUES.REQUIRED.REQUIRED_MAIL),
    password:
      (props.userModel && yup.string().nullable()) ||
      yup
        .string()
        .required(_VALUES.REQUIRED.REQUIRED_PASSWORD)
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/, _VALUES.REQUIRED.PASSWORD_POLICIES),
    role: yup.number().default(props.role),
  });

  const [showPassword, setShownPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShownPassword(!showPassword);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UserSchema),
  });

  const onSubmit = (data: any) => {
    props.action(data);
  };

  return (
    <Box>
      <form id="user-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Controller
              control={control}
              defaultValue={props.userModel?.name || ''}
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.NAME}
                  placeholder={`${_VALUES.NAME}...`}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                />
              )}
              name={'name'}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              defaultValue={props.userModel?.surnames || ''}
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.SURNAMES}
                  placeholder={`${_VALUES.SURNAMES}...`}
                  error={!!errors.surnames}
                  helperText={errors.surnames?.message}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                />
              )}
              name={'surnames'}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              defaultValue={props.userModel?.phone || ''}
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.PHONE}
                  placeholder={`${_VALUES.PHONE}...`}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                />
              )}
              name={'phone'}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              defaultValue={props.userModel?.email || ''}
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.EMAIL}
                  placeholder={`${_VALUES.EMAIL}...`}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                />
              )}
              name={'email'}
            />
          </Grid>
          {!props.userModel && (
            <Grid item>
              <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <TextFieldComponent
                    label={_VALUES.PASSWORD}
                    placeholder={`${_VALUES.PASSWORD}...`}
                    error={!!errors.password}
                    type={showPassword ? 'text' : 'password'}
                    helperText={errors.password?.message}
                    name={name}
                    inputRef={ref}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    defaultValue={value}
                    endIcon={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        size="small"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                  />
                )}
                name={'password'}
              />
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  );
};

export default UserForm;
