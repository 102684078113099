import { AuthHeader, ErrorHandler, ResponseHandler } from '../../helpers';
import { _ROUTES } from '../../resources/constants/routes';
import { CreateChecklistItem } from '../../interfaces';

export const createChecklistItem = async (user: CreateChecklistItem) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: AuthHeader(),
    body: JSON.stringify(user),
  };

  return new Promise<number>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.CHECKLIST_ITEMS}`, requestOptions)
      .then(ResponseHandler)
      .then((newChecklistItemId) => {
        resolve(newChecklistItemId);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const removeChecklistItem = async (id: number) => {
  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: AuthHeader(),
  };

  return new Promise<void>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.CHECKLIST_ITEMS}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};
