import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ButtonComponent, MainWrapperComponent, TableComponent } from 'techsbcn-storybook';
import { _VALUES } from '../../../resources/constants/values';
import { SearchFilters, UserListRequest } from '../../../interfaces';
import { getUserState, useFetchUsersQuery } from '../../../redux/users/usersSlice';
import { Scope } from '../../../enums/Scope';
import { useAppSelector, usePrevious } from '../../../helpers';
import { ReduxStatus } from '../../../enums';
import { UserModal, UserRemoveModal } from '../../modals/index';

interface CompanyAdminsTableProps {
  id: number;
}

const CompanyAdminsTable: React.FC<CompanyAdminsTableProps> = (props) => {
  const defaultFilters: UserListRequest = {
    offset: 0,
    limit: 100,
    orderBy: 'id',
    orderAsc: true,
    page: 1,
    companyIds: [props.id],
    roleIds: [Scope[Scope.ADMIN].toString()],
    active: true,
  };

  const [filters, setFilters] = useState<SearchFilters>(JSON.parse(JSON.stringify(defaultFilters)));
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showAdminModal, setShowAdminModal] = useState<boolean>(false);
  const [adminModel, setAdminModel] = useState<any>();
  const [adminId, setAdminId] = useState<number>(0);
  const userCrud = useAppSelector(getUserState).userCrudState;
  const prevUserCrudState = usePrevious(userCrud.status);
  const administratorsFetch = useFetchUsersQuery(filters);

  useEffect(() => {
    if (
      prevUserCrudState !== undefined &&
      prevUserCrudState !== ReduxStatus.Succeeded &&
      userCrud.status === ReduxStatus.Succeeded
    ) {
      administratorsFetch.refetch();
    }
  }, [prevUserCrudState, administratorsFetch, userCrud]);

  return (
    <>
      <MainWrapperComponent
        headerProps={{
          enableAfter: true,
          title: _VALUES.ADMINISTRATORS,
          actions: [
            {
              children: (
                <ButtonComponent
                  label={_VALUES.CREATE_ADMIN}
                  onClick={() => {
                    setAdminModel(null);
                    setShowAdminModal(true);
                  }}
                />
              ),
            },
          ],
        }}
      >
        <TableComponent
          rows={
            administratorsFetch.data && administratorsFetch.data.items.length > 0 ? administratorsFetch.data.items : []
          }
          columns={[
            { id: 'id', label: _VALUES.ID, minWidth: 40 },
            { id: 'name', label: _VALUES.NAME, minWidth: 100 },
            { id: 'surnames', label: _VALUES.SURNAMES, minWidth: 100 },
            { id: 'email', label: _VALUES.EMAIL, minWidth: 100 },
            { id: 'phone', label: _VALUES.PHONE, minWidth: 100 },

            {
              id: 'actions',
              label: _VALUES.ACTIONS,
              action: [
                {
                  children: <FontAwesomeIcon icon={faEdit} />,
                  onClick: (row: any) => {
                    setAdminModel(row);
                    setShowAdminModal(!showAdminModal);
                  },
                },
                {
                  children: <FontAwesomeIcon icon={faTrashAlt} />,
                  onClickId: (id: number) => {
                    setAdminId(id);
                    setShowRemoveModal(!showRemoveModal);
                  },
                },
              ],
            },
          ]}
          values={_VALUES}
          totalCount={administratorsFetch.data ? administratorsFetch.data.totalCount : 0}
          baseFilters={filters}
          onFiltersChange={(filters: SearchFilters) => setFilters(filters)}
          loading={userCrud.status === ReduxStatus.Loading}
        />
      </MainWrapperComponent>

      <UserModal
        userModel={adminModel}
        modalOpen={(open: boolean) => setShowAdminModal(open)}
        openModal={showAdminModal}
        role={Scope.ADMIN}
        companyId={props.id}
      />
      <UserRemoveModal
        id={adminId}
        role={Scope.ADMIN}
        modalOpen={(open: boolean) => setShowRemoveModal(open)}
        openModal={showRemoveModal}
      />
    </>
  );
};

export default CompanyAdminsTable;
