import React, { useState, useEffect } from 'react';
import { ButtonComponent, MainWrapperComponent, SimpleTableComponent } from 'techsbcn-storybook';
import { getChecklistItemState, useFetchChecklistItemsQuery } from '../../redux/checklistItems/checklistItemsSlice';
import { _VALUES } from '../../resources/constants/values';
import { BooleanEnum } from '../../enums/BooleanEnum';
import { useAppSelector, usePrevious } from '../../helpers';
import * as _ from 'lodash';
import { ChecklistItemListRequest } from '../../interfaces/checklistItem/ChecklistItemListRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ChecklistItemCreateModal, ChecklistItemRemoveModal } from '../../components/modals';
import { Box } from '@mui/material';
import { ReduxStatus } from '../../enums';

export const ChecklistItems: React.FC = () => {
  const defaultFilters: ChecklistItemListRequest = {};
  const [openCheckModalModal, setOpenCheckModalModal] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [checklistItemId, setChecklistItemId] = useState<number>(0);
  const checklistItemsFetch = useFetchChecklistItemsQuery(defaultFilters);

  const checklistCrud = useAppSelector(getChecklistItemState).crudChecklistItem;
  const prevChecklistCrudState = usePrevious(checklistCrud.status);

  useEffect(() => {
    if (
      prevChecklistCrudState !== undefined &&
      prevChecklistCrudState !== ReduxStatus.Succeeded &&
      checklistCrud.status === ReduxStatus.Succeeded
    ) {
      checklistItemsFetch.refetch();
    }
  }, [prevChecklistCrudState, checklistItemsFetch, checklistCrud]);

  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: _VALUES.CHECKLIST_ITEMS,
          actions: [
            {
              children: (
                <ButtonComponent
                  label={_VALUES.CREATE_CHECKLISTITEM}
                  onClick={() => setOpenCheckModalModal(!openCheckModalModal)}
                />
              ),
            },
          ],
        }}
      >
        <SimpleTableComponent
          rows={checklistItemsFetch.data && checklistItemsFetch.data.length > 0 ? checklistItemsFetch.data : []}
          columns={[
            { id: 'name', label: _VALUES.NAME, minWidth: 300 },
            {
              id: 'mandatory',
              label: _VALUES.MANDATORY,
              minWidth: 300,
              isBoolean: { enumType: BooleanEnum, enumSuffix: 'YES' },
            },
            {
              id: 'actions',
              label: _VALUES.ACTIONS,
              action: [
                {
                  children: <FontAwesomeIcon icon={faTrashAlt} />,
                  onClickId: (id: number) => {
                    setChecklistItemId(id);
                    setShowRemoveModal(true);
                  },
                },
              ],
            },
          ]}
          values={_VALUES}
          loading={checklistItemsFetch.isFetching}
        />
      </MainWrapperComponent>
      <ChecklistItemCreateModal
        modalOpen={(open: boolean) => {
          setOpenCheckModalModal(open);
        }}
        openModal={openCheckModalModal}
      />
      <ChecklistItemRemoveModal
        id={checklistItemId}
        modalOpen={(open: boolean) => setShowRemoveModal(open)}
        openModal={showRemoveModal}
      />
    </Box>
  );
};
