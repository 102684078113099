import { AuthHeader, ErrorHandler, ResponseHandler } from '../../helpers';
import { _ROUTES } from '../../resources/constants/routes';
import { CreateCompany } from '../../interfaces/company/CreateCompany';
import { CompanyBase, UpdateCompany } from '../../interfaces';

export const create = async (request: CreateCompany) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: AuthHeader(),
    body: JSON.stringify(request),
  };

  return new Promise<number>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.COMPANIES}`, requestOptions)
      .then(ResponseHandler)
      .then((companyId: number) => {
        resolve(companyId);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const readCompany = async (companyId: number) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: AuthHeader(),
  };

  return new Promise<CompanyBase>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.COMPANIES}/${companyId}`, requestOptions)
      .then(ResponseHandler)
      .then((result: CompanyBase) => {
        resolve(result);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const updateCompany = async (id: number, company: UpdateCompany) => {
  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: AuthHeader(),
    body: JSON.stringify(company),
  };

  return new Promise<void>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.COMPANIES}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const removeCompany = async (id: number) => {
  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: AuthHeader(),
  };

  return new Promise<boolean>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.COMPANIES}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};
