export default {
  companies: 'Compañías',
  company: 'Compañía',
  createCompany: 'Crear compañía',
  createAdmin: 'Crear administrador',
  removeAdmin: 'Eliminar administrador',
  editAdmin: 'Editar administrador',
  removeCompany: 'Eliminar compañía',
  drivers: 'Drivers',
  driver: 'Driver',
  name: 'Nombre',
  surnames: 'Apellidos',
  phone: 'Teléfono',
  vehicles: 'Vehículos',
  vehicle: 'Vehículo',
  assignedVehicles: 'Furgonetas asignadas',
  createVehicle: 'Crear vehículo',
  removeVehicle: 'Eliminar vehículo',
  createDriver: 'Crear driver',
  removeDriver: 'Eliminar driver',
  registrationNumber: 'Matrícula',
  manufacturer: 'Marca',
  model: 'Modelo',
  provider: 'Proveedor',
  status: 'Estado',
  active: 'Activo',
  inactive: 'Inactivo',
  plan: 'Plan',
  administrators: 'Administradores',
  checkListItems: 'Checklist Items',
  beautyShots: 'Beauty Shots',
  assignments: 'Asignaciones',
  assignment: 'Asignación',
  incidences: 'Incidencias',
  incidence: 'Incidencia',
  dni: 'DNI',
  add: 'Añadir',
  city: 'Ciudad',
  email: 'Email',
  search: 'Buscar',
  requiredField: '¡Campo requerido!',
  requiredMail: '¡Email requerido!',
  requiredPassword: '¡Contraseña requerida!',
  requiredSamePassword: 'Las contraseñas no coinciden',
  resetPasswordSuccess: 'Mail enviado, por favor revise su correo electronico',
  requiredPasswordPolices: 'Debe contener un mínimo de 6 caracteres, uno en mayúscula, uno en minúscula y un número',
  password: 'Contraseña',
  resetPassword: 'Cambiar contraseña',
  newPassword: 'Nueva contraseña',
  repeatPassword: 'Repite contraseña',
  login: 'Login',
  logout: 'Logout',
  cancel: 'Cancelar',
  forgotPasswordText: '¿Ha olvidado su contraseña?',
  confirm: 'Confirmar',
  remove: 'Eliminar',
  entityDoesNotExistException: 'No se puede acceder al recurso solicitado',
  NotFoundException: 'No se ha encontrado',
  notAuthorizedException: 'Credenciales inválidas',
  failedToFetchException: 'Error de conexión con el servidor',
  forbiddenException: 'No tienes permisos necesarios',
  fileNotFoundException: 'Se ha producido un error en la lectura del archivo',
  UserAlreadyExistsException: 'El usuario ya existe',
  EntityDoesNotExistException: 'No se puede acceder al recurso solicitado',
  NotAuthorizedException: 'Credenciales inválidas',
  InternalServerErrorException: 'Error: Ponte en contacto con el Administrador',
  BadRequestException: 'Error: Ponte en contacto con el Administrador',
  InvalidArgumentException: 'Error: Ponte en contacto con el Administrador',
  InvalidCredentialException: 'Error con las credenciales de acceso',
  InvalidCredentialsException: 'Error con las credenciales de acceso',
  PasswordPolicyException: 'El password introducido no cumple con los criterios de seguridad',
  TemplateErrorException: 'Error en la creación del template',
  FailedToFetchException: 'Error de conexión con el servidor',
  ForbiddenException: 'No tienes permisos necesarios',
  FileNotFoundException: 'Se ha producido un error en la lectura del archivo',
  FileUploadErrorException: 'Se ha producido un error en la subida del archivo',
  logOut: 'Cerrar sesión',
  noResultsFound: 'No hay resultados',
  symbolEuro: '€',
  results: 'Resultados',
  showing: 'Mostrando',
  emailSendingMessage: 'Te enviaremos un email para la recuperación',
  enterAddress: 'Introduce tu dirección',
  accountRecovery: 'Recuperar cuenta',
  poweredBy: 'Powered by',
  tableLabelDisplay: '{{0}}-{{1}} de {{2}}',
  id: 'Id',
  date: 'Fecha',
  basicInfo: 'Información básica',
  fiscalInfo: 'Información fiscal',
  contactInfo: 'Información de contacto',
  removeVehicleTextInfo: '¿Está seguro que desea eliminar el vehículo?',
  description: 'Descripción',
  removeDriverTextInfo: '¿Está seguro que desea eliminar el driver?',
  removeCompanyTextInfo: '¿Está seguro que desea eliminar la compañía?',
  removeAdminTextInfo: '¿Está seguro que desea eliminar el administrador?',
  enable: 'Activo',
  disable: 'Inactivo',
  fiscalId: 'NIF',
  fiscalName: 'Nombre fiscal',
  open: 'Abierta',
  close: 'Cerrada',
  yes: 'Sí',
  no: 'No',
  address: 'Dirección',
  postalCode: 'Código postal',
  van: 'Furgoneta',
  vans: 'Furgonetas',
  region: 'Provincia',
  country: 'País',
  actions: 'Acciones',
  goBack: 'Volver',
  checkCompleted: 'Check completado',
  createAssignment: 'Crear asignación',
  exportToExcel: 'Exportar a excel',
  mileage: 'Kilometraje',
  scheduledFor: 'Programada',
  completedOn: 'Completada',
  removeAssignmentTextInfo: '¿Está seguro de que desea eliminar la asignación?',
  removeAssignment: 'Eliminar asignación',
  downloading: 'Descargando',
  mandatory: 'Obligatorio',
  createChecklistItem: 'Crear Checklist Item',
  removeChecklistItem: 'Eliminar Checklist Item',
  removeChecklistItemTextInfo: '¿Está seguro de que desea eliminar el checklist item?',
  photo: 'Fotografía',
  createBeautyShot: 'Crear Beauty Shot',
  removeBeautyShot: 'Eliminar Beauty Shot',
  removeBeautyShotTextInfo: '¿Está seguro de que desea eliminar la Beauty Shot?',
  changeImage: 'Cambiar imagen',
  image: 'Imagen',
  editBeautyShot: 'Editar Beauty Shot',
};
