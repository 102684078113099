import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MainWrapperComponent, SimpleTableComponent } from 'techsbcn-storybook';
import { useFetchAssignmentIncidencesQuery } from '../../../redux/assignments/assignmentsSlice';
import { _VALUES } from '../../../resources/constants/values';
import { BooleanEnum } from '../../../enums/BooleanEnum';
import * as _ from 'lodash';
import { _ROUTES } from '../../../resources/constants/routes';

interface IncidencesTableProps {
  assignmentId: number;
}

const IncidencesTable: React.FC<IncidencesTableProps> = (props) => {
  const incidencesFetch = useFetchAssignmentIncidencesQuery(props.assignmentId);
  const navigate = useNavigate();

  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.INCIDENCES,
      }}
    >
      <SimpleTableComponent
        rows={incidencesFetch.data && incidencesFetch.data.length > 0 ? incidencesFetch.data : []}
        columns={[
          { id: 'description', label: _VALUES.DESCRIPTION, minWidth: 100 },
          { id: 'createdOn', label: _VALUES.DATE, minWidth: 300, isDate: true },
          {
            id: 'active',
            label: _VALUES.STATUS,
            minWidth: 300,
            isBoolean: { enumType: BooleanEnum, enumSuffix: 'OPEN' },
          },
        ]}
        values={_VALUES}
        loading={incidencesFetch.isFetching}
        onClickRow={(id: number) => navigate(`${_ROUTES.INCIDENCES}/${id}`)}
      />
    </MainWrapperComponent>
  );
};

export default IncidencesTable;
