import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { AppHeader, AppSidebar, MainLayoutSimple, UserInfo } from 'techsbcn-storybook';
import furgoCheck from '../../content/img/furgoCheck.png';
import { readUser, getUserState } from '../../redux/users/usersSlice';
import { GetUserIdToken, RemoveToken, useAppDispatch, useAppSelector } from '../../helpers';
import { ReduxStatus } from '../../enums';
import { VerticalNav } from 'techsbcn-storybook';
import { ItemsNav } from '../../resources/constants/routeConfig';
import { getAuthState } from '../../redux/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { _ROUTES } from '../../resources/constants/routes';
import { _VALUES } from '../../resources/constants/values';
import TechsbcnPoweredComponent from '../shared/TechsbcnPoweredComponent';

interface LayoutProps {
  mainComponent: any;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const navigate = useNavigate();
  const logout = () => {
    RemoveToken();
    navigate(_ROUTES.LOGIN);
  };

  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const userState = useAppSelector(getUserState).userRead;
  const roleState = useAppSelector(getAuthState).role;

  useEffect(() => {
    userState.status === ReduxStatus.Idle && dispatch(readUser(GetUserIdToken()));
  }, [userState, dispatch]);

  return (
    <MainLayoutSimple
      headerComponent={
        <AppHeader handleDrawerOpen={() => setOpen(!open)}>
          <Box pt={2} m={'auto'}>
            <img src={furgoCheck} className="header-logo" alt="logo" />
          </Box>
        </AppHeader>
      }
      lateralComponent={
        <AppSidebar
          open={open}
          onHide={() => setOpen(false)}
          headerComponents={
            <UserInfo
              displayName={
                userState.status === ReduxStatus.Loading ? '' : `${userState.user?.name} ${userState.user?.surnames}`
              }
              menuActions={[{ action: () => logout(), title: _VALUES.LOGOUT }]}
            />
          }
          footerComponents={
            <Box mb={2}>
              <TechsbcnPoweredComponent />
            </Box>
          }
        >
          <VerticalNav items={ItemsNav} userPermisions={[roleState]} onHide={() => setOpen(false)} />
        </AppSidebar>
      }
      mainComponent={<Box py={2}>{props.mainComponent}</Box>}
    />
  );
};
export default Layout;
