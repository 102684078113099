import React from 'react';
import { ModalComponent } from 'techsbcn-storybook';
import { useAppDispatch, useAppSelector } from '../../../helpers';
import { _VALUES } from '../../../resources/constants/values';
import { Box } from '@mui/material';
import { _ROUTES } from '../../../resources/constants/routes';
import { checklistItemRemove, getChecklistItemState } from '../../../redux/checklistItems/checklistItemsSlice';
import { ReduxStatus } from '../../../enums';

interface ChecklistItemRemoveModalProps {
  modalOpen: (open: boolean) => void;
  openModal: boolean;
  id: number;
}

export const ChecklistItemRemoveModal: React.FC<ChecklistItemRemoveModalProps> = (props) => {
  const checklistCrud = useAppSelector(getChecklistItemState).crudChecklistItem;
  const dispatch = useAppDispatch();

  const onClick = async (data: number) => {
    dispatch(checklistItemRemove(data)).finally(() => {
      props.modalOpen(false);
    });
  };

  return (
    <ModalComponent
      title={_VALUES.REMOVE_CHECKLISTITEM}
      setShow={props.openModal}
      onHide={() => props.modalOpen(false)}
      cancelButton={{ label: _VALUES.CANCEL }}
      submitButton={{
        label: _VALUES.CONFIRM,
        onClick: () => onClick(props.id),
      }}
      loading={checklistCrud.status === ReduxStatus.Loading}
    >
      <Box>{_VALUES.REMOVE_CHECKLISTITEM_TEXT_INFO}</Box>
    </ModalComponent>
  );
};
