import React from 'react';
import { ModalComponent } from 'techsbcn-storybook';
import { useAppDispatch, useAppSelector } from '../../../helpers';
import { _VALUES } from '../../../resources/constants/values';
import { Box } from '@mui/material';
import { _ROUTES } from '../../../resources/constants/routes';
import { assignmentRemove, getAssignmentsState } from '../../../redux/assignments/assignmentsSlice';
import { ReduxStatus } from '../../../enums';

interface AssignmentRemoveModalProps {
  modalOpen: (open: boolean) => void;
  openModal: boolean;
  id: number;
}

export const AssignmentRemoveModal: React.FC<AssignmentRemoveModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const assigmentRemove = useAppSelector(getAssignmentsState).removeAssignment;

  const onClick = async (assignmentId: number) => {
    dispatch(assignmentRemove(assignmentId)).finally(() => {
      props.modalOpen(false);
    });
  };

  return (
    <ModalComponent
      title={_VALUES.REMOVE_ASSIGNMENT}
      setShow={props.openModal}
      onHide={() => props.modalOpen(false)}
      cancelButton={{ label: _VALUES.CANCEL }}
      submitButton={{
        label: _VALUES.CONFIRM,
        onClick: () => onClick(props.id),
      }}
      loading={assigmentRemove.status === ReduxStatus.Loading}
    >
      <Box>{_VALUES.REMOVE_ASSIGNMENT_TEXT_INFO}</Box>
    </ModalComponent>
  );
};
