export const _ROUTES = {
  API: process.env.REACT_APP_API_URL,
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  RESET_ACCOUNT: '/reset-account',
  RECOVERY_TOKENS: '/recovery-tokens',
  CREDENTIALS: '/credentials',
  AUTH: '/auth',
  FILES: '/files',
  COMPANIES: '/companies',
  DRIVERS: '/drivers',
  VEHICLES: '/vehicles',
  CREATE_VEHICLE: '/create-vehicle',
  CHECKLIST_ITEMS: '/check-list-items',
  BEAUTY_SHOTS: '/beauty-shots',
  ASSIGNMENTS: '/assignments',
  INCIDENCES: '/incidences',
  USERS: '/users',
  PLANS: '/plans',
};
