import { BooleanEnum } from '../../enums/BooleanEnum';
import { BasicEntity } from '../shared/BasicEntity';

export interface VehicleBase {
  id?: number;
  registrationNumber?: string;
  manufacturer?: string;
  model?: string;
  provider?: string;
  active?: BooleanEnum;
  company?: BasicEntity;
}

export const VehicleBaseDefaults: VehicleBase = {
  registrationNumber: '',
  manufacturer: '',
  model: '',
  provider: '',
};
