import React from 'react';
import { ModalComponent, TextFieldComponent } from 'techsbcn-storybook';
import { _VALUES } from '../../../resources/constants/values';
import { checklistItemCreate, getChecklistItemState } from '../../../redux/checklistItems/checklistItemsSlice';
import { _ROUTES } from '../../../resources/constants/routes';
import { useAppDispatch, useAppSelector } from '../../../helpers';
import { Checkbox, Grid, Typography, Box } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { ReduxStatus } from '../../../enums';

interface ChecklistItemCreateModalProps {
  modalOpen: (open: boolean) => void;
  openModal: boolean;
}

export const ChecklistItemCreateModal: React.FC<ChecklistItemCreateModalProps> = (props) => {
  const checklistCrud = useAppSelector(getChecklistItemState).crudChecklistItem;

  const ChecklistItemSchema = yup.object().shape({
    name: yup.string().required(_VALUES.REQUIRED.REQUIRED_FIELD),
    mandatory: yup.boolean(),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ChecklistItemSchema),
  });

  const dispatch = useAppDispatch();

  const onSubmit = (data: any) => {
    dispatch(checklistItemCreate(data)).finally(() => {
      props.modalOpen(false);
      reset({
        name: '',
        mandatory: false,
      });
    });
  };

  return (
    <ModalComponent
      title={_VALUES.CREATE_CHECKLISTITEM}
      setShow={props.openModal}
      onHide={() => {
        props.modalOpen(false);
        reset({
          name: '',
          mandatory: false,
        });
      }}
      cancelButton={{ label: _VALUES.CANCEL }}
      submitButton={{
        label: _VALUES.CONFIRM,
        form: 'checklistitem-create-form',
        type: 'submit',
      }}
      loading={checklistCrud.status === ReduxStatus.Loading}
    >
      <form id="checklistitem-create-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.NAME}
                  placeholder={`${_VALUES.NAME}...`}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  defaultValue={value}
                />
              )}
              name={'name'}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <Box display="flex" alignItems="center">
                  <Checkbox
                    placeholder={_VALUES.MANDATORY}
                    name={name}
                    inputRef={ref}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    defaultValue={value}
                  />
                  <Typography>{_VALUES.MANDATORY}</Typography>
                </Box>
              )}
              name={'mandatory'}
            />
          </Grid>
        </Grid>
      </form>
    </ModalComponent>
  );
};
