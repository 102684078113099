import Translate from '../../i18n';
import { ObjectKey } from '../../interfaces';

export const _VALUES: ObjectKey = {
  COMPANIES: Translate.t('companies'),
  COMPANY: Translate.t('company'),
  CREATE_COMPANY: Translate.t('createCompany'),
  CREATE_ADMIN: Translate.t('createAdmin'),
  EDIT_ADMIN: Translate.t('editAdmin'),
  REMOVE_ADMIN: Translate.t('removeAdmin'),
  REMOVE_COMPANY: Translate.t('removeCompany'),
  DRIVERS: Translate.t('drivers'),
  DRIVER: Translate.t('driver'),
  VEHICLES: Translate.t('vehicles'),
  ASSIGNEDVEHICLES: Translate.t('assignedVehicles'),
  NAME: Translate.t('name'),
  SURNAMES: Translate.t('surnames'),
  PHONE: Translate.t('phone'),
  VEHICLE: Translate.t('vehicle'),
  CREATE_VEHICLE: Translate.t('createVehicle'),
  REMOVE_VEHICLE: Translate.t('removeVehicle'),
  CREATE_DRIVER: Translate.t('createDriver'),
  REMOVE_DRIVER: Translate.t('removeDriver'),
  REGISTRATIONNUMBER: Translate.t('registrationNumber'),
  MANUFACTURER: Translate.t('manufacturer'),
  MODEL: Translate.t('model'),
  PROVIDER: Translate.t('provider'),
  STATUS: Translate.t('status'),
  ACTIVE: Translate.t('active'),
  INACTIVE: Translate.t('inactive'),
  PLAN: Translate.t('plan'),
  ADMINISTRATORS: Translate.t('administrators'),
  CHECKLIST_ITEMS: Translate.t('checkListItems'),
  BEAUTY_SHOTS: Translate.t('beautyShots'),
  ASSIGNMENTS: Translate.t('assignments'),
  ASSIGNMENT: Translate.t('assignment'),
  INCIDENCES: Translate.t('incidences'),
  INCIDENCE: Translate.t('incidence'),
  ADD: Translate.t('add'),
  CITY: Translate.t('city'),
  EMAIL: Translate.t('email'),
  SEARCH: Translate.t('search'),
  DNI: Translate.t('dni'),
  PASSWORD: Translate.t('password'),
  RESET_PASSWORD: Translate.t('resetPassword'),
  NEW_PASSWORD: Translate.t('newPassword'),
  REPEAT_PASSWORD: Translate.t('repeatPassword'),
  RESET_PASSWORD_SUCCESS: Translate.t('resetPasswordSuccess'),
  LOGIN: Translate.t('login'),
  CANCEL: Translate.t('cancel'),
  CONFIRM: Translate.t('confirm'),
  REMOVE: Translate.t('remove'),
  DATE: Translate.t('date'),
  FORGOT_PASSWORD_TEXT: Translate.t('forgotPasswordText'),
  ENTITY_DOES_NOT_EXIST_EXCEPTION: Translate.t('entityDoesNotExistException'),
  NOT_AUTHORIZED_EXCEPTION: Translate.t('notAuthorizedException'),
  FAILED_TO_FETCH_EXCEPTION: Translate.t('failedToFetchException'),
  FORBIDDEN_EXCEPTION: Translate.t('forbiddenException'),
  FILE_NOT_FOUND_EXCEPTION: Translate.t('fileNotFoundException'),
  LOGOUT: Translate.t('logOut'),
  NO_RESULTS_FOUND: Translate.t('noResultsFound'),
  SYMBOL_EURO: Translate.t('symbolEuro'),
  RESULTS: Translate.t('results'),
  SHOWING: Translate.t('showing'),
  ENTER_ADDRESS: Translate.t('enterAddress'),
  EMAIL_SENDING_MESSAGE: Translate.t('emailSendingMessage'),
  ACCOUNT_RECOVERY: Translate.t('accountRecovery'),
  REQUIRED: {
    REQUIRED_FIELD: Translate.t('requiredField'),
    REQUIRED_MAIL: Translate.t('requiredMail'),
    REQUIRED_PASSWORD: Translate.t('requiredPassword'),
    REQUIRED_SAME_PASSWORD: Translate.t('requiredSamePassword'),
    PASSWORD_POLICIES: Translate.t('requiredPasswordPolices'),
  },
  POWERED_BY: Translate.t('poweredBy'),
  TABLE_LABEL_DISPLAY: (form: any, to: any, count: any) => Translate.t('tableLabelDisplay', [form, to, count]),
  ID: Translate.t('id'),
  FISCAL_ID: Translate.t('fiscalId'),
  FISCAL_NAME: Translate.t('fiscalName'),
  ADDRESS: Translate.t('address'),
  POSTAL_CODE: Translate.t('postalCode'),
  REGION: Translate.t('region'),
  COUNTRY: Translate.t('country'),
  BASIC_INFO: Translate.t('basicInfo'),
  FISCAL_INFO: Translate.t('fiscalInfo'),
  CONTACT_INFO: Translate.t('contactInfo'),
  REMOVE_VEHICLE_TEXT_INFO: Translate.t('removeVehicleTextInfo'),
  DESCRIPTION: Translate.t('description'),
  REMOVE_DRIVER_TEXT_INFO: Translate.t('removeDriverTextInfo'),
  REMOVE_COMPANY_TEXT_INFO: Translate.t('removeCompanyTextInfo'),
  REMOVE_ADMIN_TEXT_INFO: Translate.t('removeAdminTextInfo'),
  TRUE_ACTIVE: Translate.t('enable'),
  FALSE_ACTIVE: Translate.t('disable'),
  TRUE_OPEN: Translate.t('open'),
  FALSE_OPEN: Translate.t('close'),
  ACTIONS: Translate.t('actions'),
  VAN: Translate.t('van'),
  YES: Translate.t('yes'),
  NO: Translate.t('no'),
  CHECK_COMPLETED: Translate.t('checkCompleted'),
  VANS: Translate.t('vans'),
  CREATE_ASSIGNMENT: Translate.t('createAssignment'),
  EXPORT_TO_EXCEL: Translate.t('exportToExcel'),
  TRUE_YES: Translate.t('yes'),
  FALSE_YES: Translate.t('no'),
  MILEAGE: Translate.t('mileage'),
  PASSWORD_POLICY_EXCEPTION: Translate.t('PasswordPolicyException'),
  GO_BACK: Translate.t('goBack'),
  SCHEDULED_FOR: Translate.t('scheduledFor'),
  COMPLETED_ON: Translate.t('completedOn'),
  REMOVE_ASSIGNMENT: Translate.t('removeAssignment'),
  REMOVE_ASSIGNMENT_TEXT_INFO: Translate.t('removeAssignmentTextInfo'),
  DOWNLOADING: Translate.t('downloading'),
  MANDATORY: Translate.t('mandatory'),
  CREATE_CHECKLISTITEM: Translate.t('createChecklistItem'),
  REMOVE_CHECKLISTITEM: Translate.t('removeChecklistItem'),
  REMOVE_CHECKLISTITEM_TEXT_INFO: Translate.t('removeChecklistItemTextInfo'),
  PHOTO: Translate.t('photo'),
  CREATE_BEAUTYSHOT: Translate.t('createBeautyShot'),
  REMOVE_BEAUTYSHOT: Translate.t('removeBeautyShot'),
  REMOVE_BEAUTYSHOT_TEXT_INFO: Translate.t('removeBeautyShotTextInfo'),
  CHANGE_IMAGE: Translate.t('changeImage'),
  IMAGE: Translate.t('image'),
  EDIT_BEAUTYSHOT: Translate.t('editBeautyShot'),
};
