import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReduxStatus } from '../../enums';
import { AuthResult, AuthRequest } from '../../interfaces';
import { _ROUTES } from '../../resources/constants/routes';
import { RootState } from '../store';
import { login } from './authAPI';
import { GetUserRoles } from '../../helpers';

export interface UserAuthState {
  user?: AuthResult;
  status: ReduxStatus;
}

export interface AuthState {
  userAuth: UserAuthState;
  role: string;
}

const initialStateAuth: AuthState = {
  userAuth: { status: ReduxStatus.Idle },
  role: GetUserRoles(),
};

export const loginUser = createAsyncThunk<AuthResult, AuthRequest>(
  `${_ROUTES.AUTH}}`,
  async (request: AuthRequest, { rejectWithValue }) => {
    return await login(request).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialStateAuth,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.userAuth.status = ReduxStatus.Loading;
      })
      .addCase(loginUser.rejected, (state) => {
        state.userAuth.status = ReduxStatus.Failed;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.userAuth.status = ReduxStatus.Succeeded;
        state.userAuth.user = action.payload;
        state.role = GetUserRoles();
      });
  },
});

export const getAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;
