import React from 'react';
import { Grid } from '@mui/material';
import { ModalComponent, SelectAsyncField, TextFieldComponent } from 'techsbcn-storybook';
import { _VALUES } from '../../../resources/constants/values';
import { useForm, Controller } from 'react-hook-form';
import plansListSimple from '../../../redux/plans/plansAPI';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { create } from '../../../redux/companies/companiesAPI';
import { _ROUTES } from '../../../resources/constants/routes';
import { useNavigate } from 'react-router-dom';

interface CompanyCreateModalProps {
  modalOpen: (open: boolean) => void;
  openModal: boolean;
}

export const CompanyCreateModal: React.FC<CompanyCreateModalProps> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const CompanySchema = yup.object().shape({
    name: yup.string().required(_VALUES.REQUIRED.REQUIRED_FIELD),
    plan: yup
      .object()
      .shape({
        id: yup.number().required(_VALUES.REQUIRED.REQUIRED_FIELD),
        name: yup.string().required(_VALUES.REQUIRED.REQUIRED_FIELD),
      })
      .required(_VALUES.REQUIRED.REQUIRED_FIELD),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CompanySchema),
  });

  const onSubmit = (data: any) => {
    setLoading(true);
    create(data)
      .then((id: number) => {
        props.modalOpen(false);
        setLoading(false);
        navigate(`${_ROUTES.COMPANIES}/${id}`);
      })
      .catch(() => {
        setLoading(false);
        props.modalOpen(false);
      });
  };

  return (
    <ModalComponent
      title={_VALUES.CREATE_COMPANY}
      setShow={props.openModal}
      onHide={() => props.modalOpen(false)}
      cancelButton={{ label: _VALUES.CANCEL }}
      submitButton={{
        label: _VALUES.CONFIRM,
        form: 'company-create-form',
        type: 'submit',
      }}
      loading={loading}
    >
      <form id="company-create-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.NAME}
                  placeholder={`${_VALUES.NAME}...`}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  defaultValue={value}
                />
              )}
              name={'name'}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <SelectAsyncField
                  label={_VALUES.PLAN}
                  name={name}
                  invalid={!!errors.plan?.name}
                  helperText={errors.plan?.name.message}
                  placeholder={`${_VALUES.PLAN}...`}
                  inputRef={ref}
                  searchPromise={plansListSimple}
                  defaultOptions={value}
                  onChangeOption={(value, label) => {
                    onChange({ id: value, name: label });
                  }}
                  isClearable={false}
                />
              )}
              name={'plan'}
            />
          </Grid>
        </Grid>
      </form>
    </ModalComponent>
  );
};
