import { BasicEntity } from '../shared/BasicEntity';

export interface AssignmentBase {
  id?: number;
  scheduledFor?: string;
  completedOn?: string;
  vehicle?: BasicEntity;
  driver?: BasicEntity;
  mileage?: number;
}

export const AssignmentBaseDefaults: AssignmentBase = {
  mileage: 0,
};
