import { Grid, Box } from '@mui/material';
import React from 'react';
import { MainWrapperComponent } from 'techsbcn-storybook';
import { useFetchAssignmentBeautyShotsQuery } from '../../../redux/assignments/assignmentsSlice';
import { _VALUES } from '../../../resources/constants/values';
import ChangeImage from '../../shared/ChangeImage';

interface BeautyShotsProps {
  assignmentId: number;
}

const BeautyShots: React.FC<BeautyShotsProps> = (props) => {
  const beautyShotsFetch = useFetchAssignmentBeautyShotsQuery(props.assignmentId);

  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.BEAUTY_SHOTS,
      }}
    >
      <Grid container spacing={1}>
        {beautyShotsFetch.data &&
          beautyShotsFetch.data.map((beautyShot, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Box
                display="flex"
                alignContent="center"
                flexDirection="column"
                alignItems="center"
                className={`beauty-shot-component ${!beautyShot.response ? 'template-img' : ''}`}
              >
                <ChangeImage guid={beautyShot.response || beautyShot.template} />
                <Box>{beautyShot.name}</Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    </MainWrapperComponent>
  );
};

export default BeautyShots;
