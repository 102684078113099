import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainWrapperComponent } from 'techsbcn-storybook';
import { AssignmentBase, AssignmentBaseDefaults, UpdateAssignment } from '../../interfaces';
import { readAssignment, updateAssignment } from '../../redux/assignments/assignmentsAPI';
import { _VALUES } from '../../resources/constants/values';
import BasicAssignmnetInfoForm from '../../components/assignments/forms/BasicAssignmnetInfoForm';
import IncidencesTable from '../../components/assignments/tables/IncidencesTable';
import BeautyShots from '../../components/assignments/components/BeautyShots';
import CheckListItems from '../../components/assignments/components/CheckListItems';
import * as _ from 'lodash';
import { deepOmit, isEmptyValues } from '../../helpers';

export const Assignment: React.FC = () => {
  const { id } = useParams() as {
    id: string;
  };

  const [assignment, setAssignment] = useState<AssignmentBase>();
  const [loadingAssignment, setLoadingAssignmnet] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  useEffect(() => {
    let isActive = true;
    setLoadingAssignmnet(true);
    readAssignment(parseInt(id))
      .then((result) => {
        if (isActive) {
          const assigmentMerge: any = _.merge(
            deepOmit(JSON.parse(JSON.stringify(AssignmentBaseDefaults)), _.isNil),
            deepOmit(JSON.parse(JSON.stringify(result)), _.isNil)
          );
          setAssignment(assigmentMerge);
          setLoadingAssignmnet(false);
        }
      })
      .catch(() => {
        setLoadingAssignmnet(false);
      });

    return () => {
      isActive = false;
    };
  }, [id]);

  const onSubmit = (data: UpdateAssignment) => {
    setLoadingUpdate(true);
    updateAssignment(
      Object.assign({} as UpdateAssignment, deepOmit(deepOmit(data, isEmptyValues), isEmptyValues)),
      id
    ).finally(() => {
      setLoadingUpdate(false);
    });
  };

  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: `${_VALUES.ASSIGNMENT} #${id}`,
        }}
        loading={loadingAssignment}
      />
      <Box my={2}>
        <BasicAssignmnetInfoForm
          action={onSubmit}
          readModel={assignment}
          loading={loadingAssignment}
          loadingUpdate={loadingUpdate}
        />
      </Box>
      <Box my={2}>
        <BeautyShots assignmentId={parseInt(id)} />
      </Box>
      <Box my={2}>
        <CheckListItems assignmentId={parseInt(id)} />
      </Box>
      <Box my={2}>
        <IncidencesTable assignmentId={parseInt(id)} />
      </Box>
    </Box>
  );
};
