import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { _ROUTES } from '../resources/constants/routes';

const tokenName = process.env.REACT_APP_TOKEN_NAME as string;

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: _ROUTES.API,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenName) ? JSON.parse(localStorage.getItem(tokenName) || '') : null;
      if (token) headers.set('authorization', `Bearer ${token}`);
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}),
});
