import React from 'react';
import { Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _VALUES } from '../../../resources/constants/values';
import { MainWrapperComponent, TextFieldComponent } from 'techsbcn-storybook';
import { debounce } from 'lodash';
import { CompanyBase } from '../../../interfaces';

interface CompanyContactInfoFormProps {
  action: (data: any) => void;
  readModel?: CompanyBase;
  loading: boolean;
  loadingUpdate: boolean;
}

const CompanyContactInfoForm: React.FC<CompanyContactInfoFormProps> = (props) => {
  const ContactInfoSchema = yup.object().shape({
    name: yup.string(),
    phone: yup.string(),
    email: yup.string(),
  });

  const { getValues, control } = useForm({
    resolver: yupResolver(ContactInfoSchema),
  });

  const onSubmit = (data: any) => {
    props.action(data);
  };

  const setUpdate = debounce(() => {
    onSubmit({ contact: getValues() });
  }, 1000);

  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.CONTACT_INFO,
      }}
      loadingChildren={props.loading}
      loadingProgressBar={props.loadingUpdate}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            defaultValue={props.readModel?.contact.name}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.NAME}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'name'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            defaultValue={props.readModel?.contact.phone}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.PHONE}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'phone'}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            defaultValue={props.readModel?.contact.email}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.EMAIL}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'email'}
          />
        </Grid>
      </Grid>
    </MainWrapperComponent>
  );
};

export default CompanyContactInfoForm;
