import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@mui/material';
import { Search } from '@mui/icons-material';
import { MainWrapperComponent, TableComponent, TextFieldComponent, SelectField } from 'techsbcn-storybook';
import { useFetchAssignmentsQuery } from '../../../redux/assignments/assignmentsSlice';
import { _VALUES } from '../../../resources/constants/values';
import { SearchFilters } from '../../../interfaces';
import * as _ from 'lodash';
import { EnumToSelect } from '../../../helpers';
import { BooleanEnum } from '../../../enums';
import { _ROUTES } from '../../../resources/constants/routes';

interface AssignedVehiclesTableProps {
  driverId: number;
}

const AssignedVehiclesTable: React.FC<AssignedVehiclesTableProps> = (props) => {
  const navigate = useNavigate();
  const defaultFilters: SearchFilters = {
    offset: 0,
    limit: 100,
    orderBy: 'id',
    orderAsc: true,
    page: 1,
    driverIds: [props.driverId],
  };

  const [filters, setFilters] = useState<SearchFilters>(JSON.parse(JSON.stringify(defaultFilters)));
  const assigmentsFetch = useFetchAssignmentsQuery(filters);

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFilters(event.target.name, event.target.value);
  };

  const setNewFilters = (name: string, value: any) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[name] = value;
    newFilters.page = 1;
    newFilters.offset = 0;
    setFilters(newFilters);
  };

  const applyTableFilters = (filtersTable: SearchFilters) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters.page = filtersTable.page;
    newFilters.limit = filtersTable.limit;
    newFilters.orderBy = filtersTable.orderBy;
    newFilters.orderAsc = filtersTable.orderAsc;
    newFilters.offset = filtersTable.offset;
    setFilters(newFilters);
  };

  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.ASSIGNEDVEHICLES,
        filters: [
          {
            singleFilter: (
              <TextFieldComponent
                name="vehicleRegistrationNumber"
                label={_VALUES.REGISTRATIONNUMBER}
                placeholder={`${_VALUES.REGISTRATIONNUMBER}...`}
                onChange={onHandleChange}
                startIcon={
                  <Icon>
                    <Search />
                  </Icon>
                }
              />
            ),
          },
          {
            singleFilter: (
              <TextFieldComponent
                name="scheduledFor"
                type="date"
                label={_VALUES.DATE}
                onChange={_.debounce(async (e) => onHandleChange(e), 1000)}
                placeholder={`${_VALUES.DATE}...`}
              />
            ),
          },
          {
            singleFilter: (
              <SelectField
                name="withIncidences"
                label={_VALUES.INCIDENCES}
                placeholder={`${_VALUES.INCIDENCES}...`}
                options={EnumToSelect(BooleanEnum, 'YES')}
                onChange={(value: any, name: string) => setNewFilters(name, value)}
              />
            ),
          },
        ],
      }}
    >
      <TableComponent
        rows={assigmentsFetch.data && assigmentsFetch.data.items.length > 0 ? assigmentsFetch.data.items : []}
        columns={[
          { id: 'vehicleRegistrationNumber', label: _VALUES.REGISTRATIONNUMBER, minWidth: 100 },
          { id: 'scheduledFor', label: _VALUES.DATE, minWidth: 100, isDate: true },
          { id: 'incidencesCount', label: _VALUES.INCIDENCES, minWidth: 100 },
        ]}
        values={_VALUES}
        totalCount={assigmentsFetch.data ? assigmentsFetch.data.totalCount : 0}
        baseFilters={filters}
        onFiltersChange={(filters: SearchFilters) => applyTableFilters(filters)}
        loading={assigmentsFetch.isFetching}
        onClickRow={(id: number) => navigate(`${_ROUTES.ASSIGNMENTS}/${id}`)}
      />
    </MainWrapperComponent>
  );
};

export default AssignedVehiclesTable;
