import { AuthHeader, ErrorHandler, ResponseHandler } from '../../helpers';
import { _ROUTES } from '../../resources/constants/routes';
import { CreateBeautyShot, UpdateBeautyShot } from '../../interfaces';

export const createBeautyShot = async (user: CreateBeautyShot) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: AuthHeader(),
    body: JSON.stringify(user),
  };

  return new Promise<number>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.BEAUTY_SHOTS}`, requestOptions)
      .then(ResponseHandler)
      .then((newChecklistItemId) => {
        resolve(newChecklistItemId);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const removeBeautyShot = async (id: number) => {
  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: AuthHeader(),
  };

  return new Promise<void>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.BEAUTY_SHOTS}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const updateBeautyShot = async (id: number, beautyShot: UpdateBeautyShot) => {
  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: AuthHeader(),
    body: JSON.stringify(beautyShot),
  };

  return new Promise<void>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.BEAUTY_SHOTS}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};
