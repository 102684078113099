import { AuthHeader, ErrorHandler, ResponseBlobHandler, ResponseHandler } from '../../helpers';
import { _ROUTES } from '../../resources/constants/routes';
import { File } from '../../interfaces';

export const readFile = async (guid: string) => {
  const requestOptions = {
    method: 'GET',
    headers: AuthHeader(),
  };

  return new Promise<any>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.FILES}/${guid}`, requestOptions)
      .then(ResponseBlobHandler)
      .then((result: any) => {
        resolve(result);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const createFile = async (request: File) => {
  const requestOptions = {
    method: 'POST',
    headers: AuthHeader(),
    body: JSON.stringify(request),
  };

  return new Promise<string>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.FILES}`, requestOptions)
      .then(ResponseHandler)
      .then((guid: string) => {
        resolve(guid);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};
