import React from 'react';
import { Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _VALUES } from '../../../resources/constants/values';
import { vehicleListSimple } from '../../../redux/vehicles/vehiclesAPI';
import { usersListSimple } from '../../../redux/users/usersAPI';
import { MainWrapperComponent, SelectAsyncField, TextFieldComponent, TextSimpleComponent } from 'techsbcn-storybook';
import { debounce } from 'lodash';
import { AssignmentBase, UserListRequest } from '../../../interfaces';
import { SelectSimpleAsyncHelper } from '../../../helpers';
import { Scope } from '../../../enums';

interface BasicAssignmnetInfoFormProps {
  action: (data: any) => void;
  readModel?: AssignmentBase;
  loading: boolean;
  loadingUpdate: boolean;
}

const BasicAssignmnetInfoForm: React.FC<BasicAssignmnetInfoFormProps> = (props) => {
  const defaultDriverFilters: UserListRequest = {
    offset: 0,
    page: 1,
    orderBy: 'Name',
    limit: 20,
    orderAsc: true,
    roleIds: [Scope[Scope.DRIVER].toString()],
  };

  const BasicInfoSchema = yup.object().shape({
    vehicleId: yup.number(),
    driverId: yup.number(),
    mileage: yup.number(),
  });
  const { getValues, control, setValue } = useForm({
    resolver: yupResolver(BasicInfoSchema),
  });

  const onSubmit = (data: any) => {
    props.action(data);
  };

  const setUpdate = debounce(() => {
    onSubmit(getValues());
  }, 1000);

  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.BASIC_INFO,
      }}
      loadingChildren={props.loading}
      loadingProgressBar={props.loadingUpdate}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={2}>
          <TextSimpleComponent fullWidth noBorders={false} label={_VALUES.ID} value={props.readModel?.id} />
        </Grid>
        <Grid item xs={12} md={5}>
          <Controller
            control={control}
            defaultValue={props.readModel?.driver ? SelectSimpleAsyncHelper(props.readModel?.driver) : undefined}
            render={({ field: { onChange, value, name, ref } }) => {
              value?.value && setValue(name, value.value);
              return (
                <SelectAsyncField
                  label={_VALUES.DRIVER}
                  name={name}
                  inputRef={ref}
                  filters={() => {
                    const newFilters = JSON.parse(JSON.stringify(defaultDriverFilters));
                    return newFilters;
                  }}
                  searchPromise={usersListSimple}
                  defaultOptions={value}
                  onChange={(e) => {
                    onChange(e);
                    setUpdate();
                  }}
                  isClearable={false}
                />
              );
            }}
            name={'driverId'}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Controller
            control={control}
            defaultValue={props.readModel?.vehicle ? SelectSimpleAsyncHelper(props.readModel?.vehicle) : undefined}
            render={({ field: { onChange, value, name, ref } }) => {
              value?.value && setValue(name, value.value);
              return (
                <SelectAsyncField
                  label={_VALUES.VEHICLE}
                  name={name}
                  inputRef={ref}
                  searchPromise={vehicleListSimple}
                  defaultOptions={value}
                  onChange={(e) => {
                    onChange(e);
                    setUpdate();
                  }}
                  isClearable={false}
                />
              );
            }}
            name={'vehicleId'}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            control={control}
            defaultValue={props.readModel?.mileage}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.MILEAGE}
                name={name}
                type="number"
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'mileage'}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextSimpleComponent
            fullWidth
            label={_VALUES.SCHEDULED_FOR}
            value={props.readModel?.scheduledFor && new Date(props.readModel.scheduledFor).toLocaleString('es-ES')}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextSimpleComponent
            fullWidth
            label={_VALUES.COMPLETED_ON}
            value={
              (props.readModel?.completedOn && new Date(props.readModel.completedOn).toLocaleString('es-ES')) ||
              _VALUES.NO
            }
          />
        </Grid>
      </Grid>
    </MainWrapperComponent>
  );
};

export default BasicAssignmnetInfoForm;
