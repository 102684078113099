import { BasicEntity } from '../shared/BasicEntity';

export interface UserBase {
  id?: number;
  name: string;
  surnames: string;
  email: string;
  phone: string;
  role?: string;
  active?: string;
  profileImageUrl?: string;
  companyId?: BasicEntity;
}

export const UserBaseDefaults: UserBase = {
  name: '',
  surnames: '',
  email: '',
  phone: '',
};
