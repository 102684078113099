import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Icon } from '@mui/material';
import { Search } from '@mui/icons-material';
import { ButtonComponent, MainWrapperComponent, TableComponent, TextFieldComponent } from 'techsbcn-storybook';
import { _VALUES } from '../../resources/constants/values';
import { SearchFilters, UserListRequest } from '../../interfaces';
import { getUserState, useFetchUsersQuery } from '../../redux/users/usersSlice';
import { _ROUTES } from '../../resources/constants/routes';
import { useAppSelector, usePrevious } from '../../helpers';
import { ReduxStatus } from '../../enums';
import { Scope } from '../../enums/Scope';
import { UserModal } from '../../components/modals';

export const Drivers: React.FC = () => {
  const defaultFilters: UserListRequest = {
    offset: 0,
    limit: 100,
    orderBy: 'id',
    orderAsc: true,
    page: 1,
    roleIds: [Scope[Scope.DRIVER].toString()],
  };
  const navigate = useNavigate();
  const [openClientModal, setOpenClientModal] = useState(false);
  const [filters, setFilters] = useState<UserListRequest>(JSON.parse(JSON.stringify(defaultFilters)));
  const driverFetch = useFetchUsersQuery(filters);
  const driverUpdate = useAppSelector(getUserState).userCrudState;
  const prevDriverUpdateState = usePrevious(driverUpdate.status);

  const applyTableFilters = (filtersTable: SearchFilters) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters.page = filtersTable.page;
    newFilters.limit = filtersTable.limit;
    newFilters.orderBy = filtersTable.orderBy;
    newFilters.orderAsc = filtersTable.orderAsc;
    newFilters.offset = filtersTable.offset;
    setFilters(newFilters);
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[event.target.name] = event.target.value;
    newFilters.page = 1;
    newFilters.offset = 0;
    setFilters(newFilters);
  };

  useEffect(() => {
    if (prevDriverUpdateState !== ReduxStatus.Succeeded && driverUpdate.status === ReduxStatus.Succeeded) {
      driverFetch.refetch();
    }
  }, [prevDriverUpdateState, driverFetch, driverUpdate]);

  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: _VALUES.DRIVERS,
          searchBar: (
            <TextFieldComponent
              name="query"
              placeholder={`${_VALUES.SEARCH}...`}
              onChange={onHandleChange}
              startIcon={
                <Icon>
                  <Search />
                </Icon>
              }
            />
          ),
          filters: [
            {
              singleFilter: (
                <TextFieldComponent
                  name="name"
                  label={_VALUES.NAME}
                  placeholder={`${_VALUES.NAME}...`}
                  onChange={onHandleChange}
                  startIcon={
                    <Icon>
                      <Search />
                    </Icon>
                  }
                />
              ),
            },
            {
              singleFilter: (
                <TextFieldComponent
                  name="email"
                  label={_VALUES.EMAIL}
                  placeholder={`${_VALUES.EMAIL}...`}
                  onChange={onHandleChange}
                  startIcon={
                    <Icon>
                      <Search />
                    </Icon>
                  }
                />
              ),
            },
            {
              singleFilter: (
                <TextFieldComponent
                  name="phone"
                  label={_VALUES.PHONE}
                  placeholder={`${_VALUES.PHONE}...`}
                  onChange={onHandleChange}
                  startIcon={
                    <Icon>
                      <Search />
                    </Icon>
                  }
                />
              ),
            },
          ],
          actions: [
            {
              children: (
                <ButtonComponent label={_VALUES.CREATE_DRIVER} onClick={() => setOpenClientModal(!openClientModal)} />
              ),
            },
          ],
        }}
      >
        <TableComponent
          rows={driverFetch.data && driverFetch.data.items.length > 0 ? driverFetch.data.items : []}
          columns={[
            { id: 'id', label: _VALUES.ID, minWidth: 20 },
            { id: 'name', label: _VALUES.NAME, minWidth: 100 },
            { id: 'surnames', label: _VALUES.SURNAMES, minWidth: 100 },
            { id: 'email', label: _VALUES.EMAIL, minWidth: 100 },
            { id: 'phone', label: _VALUES.PHONE, minWidth: 100 },
            {
              id: 'active',
              label: _VALUES.STATUS,
              minWidth: 100,
              isBoolean: { enumSuffix: 'ACTIVE' },
            },
          ]}
          values={_VALUES}
          totalCount={driverFetch.data ? driverFetch.data.totalCount : 0}
          baseFilters={filters}
          onFiltersChange={(filters: SearchFilters) => applyTableFilters(filters)}
          loading={driverFetch.isFetching}
          onClickRow={(id: number) => navigate(`${_ROUTES.DRIVERS}/${id}`)}
        />
      </MainWrapperComponent>
      <UserModal
        modalOpen={(open: boolean) => {
          setOpenClientModal(open);
        }}
        openModal={openClientModal}
        role={Scope.DRIVER}
      />
    </Box>
  );
};
