import { _ROUTES } from '../../resources/constants/routes';
import { apiSlice } from '../apiSlice';
import queryString from 'query-string';
import { ErrorHandler } from '../../helpers';
import { ChecklistItemListRequest } from '../../interfaces/checklistItem/ChecklistItemListRequest';
import { ChecklistItemListItem } from '../../interfaces/checklistItem/ChecklistItemListItem';
import { ReduxStatus } from '../../enums';
import { CreateChecklistItem } from '../../interfaces';
import { createChecklistItem, removeChecklistItem } from './checklistItemsAPI';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const checklistItemsEndpoints = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchChecklistItems: builder.query<ChecklistItemListItem[], ChecklistItemListRequest>({
        keepUnusedDataFor: 0,
        query: (request) => `${_ROUTES.CHECKLIST_ITEMS}/?${queryString.stringify(request)}`,
        async onQueryStarted(_request, { queryFulfilled }) {
          await queryFulfilled.catch((err) => {
            ErrorHandler(err);
          });
        },
      }),
    };
  },
});

interface ChecklistItemCrudState {
  status: ReduxStatus;
}
export interface ChecklistItemState {
  crudChecklistItem: ChecklistItemCrudState;
}

const initialStateChecklistItems: ChecklistItemState = {
  crudChecklistItem: { status: ReduxStatus.Idle },
};

export const checklistItemCreate = createAsyncThunk<number, CreateChecklistItem>(
  `${_ROUTES.CHECKLIST_ITEMS}/CREATE`,
  async (request: CreateChecklistItem, { rejectWithValue }) => {
    return await createChecklistItem(request).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const checklistItemRemove = createAsyncThunk<void, number>(
  `${_ROUTES.CHECKLIST_ITEMS}/DELETE`,
  async (request: number, { rejectWithValue }) => {
    return await removeChecklistItem(request).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const checklistItemsSlice = createSlice({
  name: 'users',
  initialState: initialStateChecklistItems,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(checklistItemCreate.pending, (state) => {
        state.crudChecklistItem.status = ReduxStatus.Loading;
      })
      .addCase(checklistItemCreate.rejected, (state) => {
        state.crudChecklistItem.status = ReduxStatus.Failed;
      })
      .addCase(checklistItemCreate.fulfilled, (state) => {
        state.crudChecklistItem.status = ReduxStatus.Succeeded;
      })

      .addCase(checklistItemRemove.pending, (state) => {
        state.crudChecklistItem.status = ReduxStatus.Loading;
      })
      .addCase(checklistItemRemove.rejected, (state) => {
        state.crudChecklistItem.status = ReduxStatus.Failed;
      })
      .addCase(checklistItemRemove.fulfilled, (state) => {
        state.crudChecklistItem.status = ReduxStatus.Succeeded;
      });
  },
});

export const getChecklistItemState = (state: RootState) => state.checklistItems;

export default checklistItemsSlice.reducer;

export const { useFetchChecklistItemsQuery } = checklistItemsEndpoints;
