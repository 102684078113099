import { Checkbox, Grid, Typography, Box } from '@mui/material';
import React from 'react';
import { MainWrapperComponent } from 'techsbcn-storybook';
import { useFetchAssignmentCheckListItemsQuery } from '../../../redux/assignments/assignmentsSlice';
import { _VALUES } from '../../../resources/constants/values';

interface ChecklistItemsProps {
  assignmentId: number;
}

const ChecklistItems: React.FC<ChecklistItemsProps> = (props) => {
  const checkListItemsFetch = useFetchAssignmentCheckListItemsQuery(props.assignmentId);

  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.CHECKLIST_ITEMS,
      }}
    >
      <Grid container direction="row" spacing={3}>
        {checkListItemsFetch.data &&
          checkListItemsFetch.data.map((checklistItem, index) => (
            <Grid key={index} item xs={12} md={4}>
              <Box display="flex" alignItems="center">
                <Checkbox checked={checklistItem.response} />
                <Typography noWrap>{checklistItem.name}</Typography>
              </Box>
            </Grid>
          ))}
      </Grid>
    </MainWrapperComponent>
  );
};

export default ChecklistItems;
