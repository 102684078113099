import React, { useState, useEffect } from 'react';
import { ButtonComponent, MainWrapperComponent, SimpleTableComponent } from 'techsbcn-storybook';
import { getBeautyShotsState, useFetchBeautyShotsQuery } from '../../redux/beautyShots/beautyShotsSlice';
import { _VALUES } from '../../resources/constants/values';
import { getImageByBlob, useAppSelector, usePrevious } from '../../helpers';
import * as _ from 'lodash';
import { BeautyShotListRequest } from '../../interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { BeautyShotCreateModal, BeautyShotRemoveModal } from '../../components/modals';
import { Box } from '@mui/material';
import { ReduxStatus } from '../../enums';
import { readFile } from '../../redux/files/filesAPI';

export const BeautyShots: React.FC = () => {
  const defaultFilters: BeautyShotListRequest = {};
  const [openBeautyShotModal, setOpenBeautyShotModal] = useState<boolean>(false);
  const [beautyShotModel, setBeautyShotModel] = useState<any>();
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [beautyShotId, setBeautyShotId] = useState<number>(0);
  const beautyShotsFetch = useFetchBeautyShotsQuery(JSON.parse(JSON.stringify(defaultFilters)));

  const beautyShotCrud = useAppSelector(getBeautyShotsState).crudBeautyShot;
  const prevBeautyShotCrudState = usePrevious(beautyShotCrud.status);

  useEffect(() => {
    if (
      prevBeautyShotCrudState !== undefined &&
      prevBeautyShotCrudState !== ReduxStatus.Succeeded &&
      beautyShotCrud.status === ReduxStatus.Succeeded
    ) {
      beautyShotsFetch.refetch();
    }
  }, [prevBeautyShotCrudState, beautyShotsFetch, beautyShotCrud]);

  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: _VALUES.BEAUTY_SHOTS,
          actions: [
            {
              children: (
                <ButtonComponent
                  label={_VALUES.CREATE_BEAUTYSHOT}
                  onClick={() => {
                    setBeautyShotModel(null);
                    setOpenBeautyShotModal(!openBeautyShotModal);
                  }}
                />
              ),
            },
          ],
        }}
      >
        <SimpleTableComponent
          rows={beautyShotsFetch.data && beautyShotsFetch.data.length > 0 ? beautyShotsFetch.data : []}
          columns={[
            { id: 'name', label: _VALUES.NAME, minWidth: 300 },
            {
              id: 'template',
              label: _VALUES.IMAGE,
              minWidth: 300,
              isImage: {
                isGuid: {
                  readFile: readFile,
                  convertImage: getImageByBlob,
                },
              },
            },
            {
              id: 'actions',
              label: _VALUES.ACTIONS,
              action: [
                {
                  children: <FontAwesomeIcon icon={faEdit} />,
                  onClick: (row: any) => {
                    setBeautyShotModel(row);
                    setOpenBeautyShotModal(!openBeautyShotModal);
                  },
                },
                {
                  children: <FontAwesomeIcon icon={faTrashAlt} />,
                  onClickId: (id: number) => {
                    setBeautyShotId(id);
                    setShowRemoveModal(true);
                  },
                },
              ],
            },
          ]}
          values={_VALUES}
          loading={beautyShotsFetch.isFetching}
        />
      </MainWrapperComponent>

      {openBeautyShotModal && (
        <BeautyShotCreateModal
          beautyShotModel={beautyShotModel}
          modalOpen={(open: boolean) => {
            setOpenBeautyShotModal(open);
          }}
          openModal={openBeautyShotModal}
        />
      )}

      <BeautyShotRemoveModal
        id={beautyShotId}
        modalOpen={(open: boolean) => setShowRemoveModal(open)}
        openModal={showRemoveModal}
      />
    </Box>
  );
};
