import { Box, Typography } from '@mui/material';
import React from 'react';
import { _VALUES } from '../../resources/constants/values';
import techsBCNLogo from '../../content/img/techsBCN.svg';

const TechsbcnPoweredComponent: React.FC = () => {
  const techsbcnUrl = 'https://techsbcn.com/es/';

  return (
    <Box className="techsbcn-powered-component">
      <Typography variant="caption" component="span" className="title">
        {_VALUES.POWERED_BY}
      </Typography>
      <Box>
        <a target="_blank" href={techsbcnUrl} rel="noreferrer">
          <img src={techsBCNLogo} alt="techsLogo" className="techs-bcn-logo" />
        </a>
      </Box>
    </Box>
  );
};

export default TechsbcnPoweredComponent;
