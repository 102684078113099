import { AuthHeader, ErrorHandler, ResponseHandler } from '../../helpers';
import { _ROUTES } from '../../resources/constants/routes';
import { CreateVehicle } from '../../interfaces/vehicle/CreateVehicle';
import {
  ListResultCollection,
  SimpleSearchResult,
  VehicleBase,
  UpdateVehicle,
  VehicleListRequest,
} from '../../interfaces';
import queryString from 'query-string';

export const vehicleListSimple = async (request: VehicleListRequest) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: AuthHeader(),
  };

  return new Promise<ListResultCollection<SimpleSearchResult>>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.VEHICLES}/?${queryString.stringify(request)}`, requestOptions)
      .then(ResponseHandler)
      .then((result: any) => {
        const trans = Object.assign({}, result);
        trans.items = result.items.map((item: any) => {
          const temp = Object.assign({}, item);
          temp.name = `[${item.registrationNumber}] ${item.manufacturer} ${item.model}`;
          return temp;
        });
        resolve(trans);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const create = async (request: CreateVehicle) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: AuthHeader(),
    body: JSON.stringify(request),
  };

  return new Promise<number>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.VEHICLES}`, requestOptions)
      .then(ResponseHandler)
      .then((vehicleId: number) => {
        resolve(vehicleId);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const readVehicle = async (vehicleId: number) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: AuthHeader(),
  };

  return new Promise<VehicleBase>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.VEHICLES}/${vehicleId}`, requestOptions)
      .then(ResponseHandler)
      .then((result: VehicleBase) => {
        resolve(result);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const updateVehicle = async (vehicle: UpdateVehicle, id: string) => {
  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: AuthHeader(),
    body: JSON.stringify(vehicle),
  };

  return new Promise<void>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.VEHICLES}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const removeVehicle = async (id: number) => {
  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: AuthHeader(),
  };

  return new Promise<boolean>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.VEHICLES}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};
