import React from 'react';
import { ModalComponent } from 'techsbcn-storybook';
import { useAppDispatch, useAppSelector } from '../../../helpers';
import { _VALUES } from '../../../resources/constants/values';
import { Box } from '@mui/material';
import { _ROUTES } from '../../../resources/constants/routes';
import { beautyShotRemove, getBeautyShotsState } from '../../../redux/beautyShots/beautyShotsSlice';
import { ReduxStatus } from '../../../enums';

interface BeautyShotRemoveModalProps {
  modalOpen: (open: boolean) => void;
  openModal: boolean;
  id: number;
}

export const BeautyShotRemoveModal: React.FC<BeautyShotRemoveModalProps> = (props) => {
  const crudBeautyShot = useAppSelector(getBeautyShotsState).crudBeautyShot;
  const dispatch = useAppDispatch();

  const onClick = async (beautyShotId: number) => {
    dispatch(beautyShotRemove(beautyShotId)).finally(() => {
      props.modalOpen(false);
    });
  };

  return (
    <ModalComponent
      title={_VALUES.REMOVE_BEAUTYSHOT}
      setShow={props.openModal}
      onHide={() => props.modalOpen(false)}
      cancelButton={{ label: _VALUES.CANCEL }}
      submitButton={{
        label: _VALUES.CONFIRM,
        onClick: () => onClick(props.id),
      }}
      loading={crudBeautyShot.status === ReduxStatus.Loading}
    >
      <Box>{_VALUES.REMOVE_BEAUTYSHOT_TEXT_INFO}</Box>
    </ModalComponent>
  );
};
