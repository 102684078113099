import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainWrapperComponent } from 'techsbcn-storybook';
import BasicVehicleInfoForm from '../../components/vehicles/forms/BasicVehicleInfoForm';
import { UpdateVehicle, VehicleBase, VehicleBaseDefaults } from '../../interfaces';
import { readVehicle, updateVehicle } from '../../redux/vehicles/vehiclesAPI';
import { _VALUES } from '../../resources/constants/values';
import * as _ from 'lodash';
import IncidencesTable from '../../components/vehicles/tables/IncidencesTable';
import { deepOmit, isEmptyValues } from '../../helpers';

export const Vehicle: React.FC = () => {
  const { id } = useParams() as {
    id: string;
  };

  const [vehicle, setVehicle] = useState<VehicleBase>();
  const [loadingVehicle, setLoadingVehicle] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  useEffect(() => {
    let isActive = true;

    setLoadingVehicle(true);
    readVehicle(parseInt(id))
      .then((result) => {
        if (isActive) {
          const vehicleMerge: any = _.merge(
            deepOmit(JSON.parse(JSON.stringify(VehicleBaseDefaults)), _.isNil),
            deepOmit(JSON.parse(JSON.stringify(result)), _.isNil)
          );
          setVehicle(vehicleMerge);
          setLoadingVehicle(false);
        }
      })
      .catch(() => {
        setLoadingVehicle(false);
      });
    return () => {
      isActive = false;
    };
  }, [id]);

  const onSubmit = (data: UpdateVehicle) => {
    setLoadingUpdate(true);
    updateVehicle(
      Object.assign({} as UpdateVehicle, deepOmit(deepOmit(data, isEmptyValues), isEmptyValues)),
      id
    ).finally(() => {
      setLoadingUpdate(false);
    });
  };

  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: `${_VALUES.VAN} #${id}`,
        }}
        loading={loadingVehicle}
      />
      <Box my={2}>
        <BasicVehicleInfoForm
          action={onSubmit}
          readModel={vehicle}
          loading={loadingVehicle}
          loadingUpdate={loadingUpdate}
        />
      </Box>
      <Box my={2}>
        <IncidencesTable vehicleId={parseInt(id)} />
      </Box>
    </Box>
  );
};
