import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Icon } from '@mui/material';
import { Search } from '@mui/icons-material';
import { ButtonComponent, MainWrapperComponent, TableComponent, TextFieldComponent } from 'techsbcn-storybook';
import { VehicleCreateModal } from '../../components/modals/vehicles/VehicleCreateModal';
import { _VALUES } from '../../resources/constants/values';
import { VehicleListRequest, SearchFilters } from '../../interfaces';
import { useFetchVehiclesQuery } from '../../redux/vehicles/vehicleSlice';
import { _ROUTES } from '../../resources/constants/routes';

export const Vehicles: React.FC = () => {
  const defaultFilters: VehicleListRequest = {
    offset: 0,
    limit: 100,
    orderBy: 'id',
    orderAsc: true,
    page: 1,
  };
  const navigate = useNavigate();
  const [openVehicleModal, setOpenVehicleModal] = useState(false);
  const [filters, setFilters] = useState<VehicleListRequest>(JSON.parse(JSON.stringify(defaultFilters)));
  const vehicleFetch = useFetchVehiclesQuery(filters);
  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[event.target.name] = event.target.value;
    newFilters.page = 1;
    newFilters.offset = 0;
    setFilters(newFilters);
  };

  const applyTableFilters = (filtersTable: SearchFilters) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters.page = filtersTable.page;
    newFilters.limit = filtersTable.limit;
    newFilters.orderBy = filtersTable.orderBy;
    newFilters.orderAsc = filtersTable.orderAsc;
    newFilters.offset = filtersTable.offset;
    setFilters(newFilters);
  };

  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: _VALUES.VEHICLES,
          searchBar: (
            <TextFieldComponent
              name="query"
              type="number"
              placeholder={`${_VALUES.SEARCH}...`}
              onChange={onHandleChange}
              startIcon={
                <Icon>
                  <Search />
                </Icon>
              }
            />
          ),
          filters: [
            {
              singleFilter: (
                <TextFieldComponent
                  name="registrationNumber"
                  label={_VALUES.REGISTRATIONNUMBER}
                  placeholder={`${_VALUES.REGISTRATIONNUMBER}...`}
                  onChange={onHandleChange}
                  startIcon={
                    <Icon>
                      <Search />
                    </Icon>
                  }
                />
              ),
            },
            {
              singleFilter: (
                <TextFieldComponent
                  name="manufacturer"
                  label={_VALUES.MANUFACTURER}
                  placeholder={`${_VALUES.MANUFACTURER}...`}
                  onChange={onHandleChange}
                  startIcon={
                    <Icon>
                      <Search />
                    </Icon>
                  }
                />
              ),
            },
            {
              singleFilter: (
                <TextFieldComponent
                  name="model"
                  label={_VALUES.MODEL}
                  placeholder={`${_VALUES.MODEL}...`}
                  onChange={onHandleChange}
                  startIcon={
                    <Icon>
                      <Search />
                    </Icon>
                  }
                />
              ),
            },
            {
              singleFilter: (
                <TextFieldComponent
                  name="provider"
                  label={_VALUES.PROVIDER}
                  placeholder={`${_VALUES.PROVIDER}...`}
                  onChange={onHandleChange}
                  startIcon={
                    <Icon>
                      <Search />
                    </Icon>
                  }
                />
              ),
            },
          ],
          actions: [
            {
              children: (
                <ButtonComponent
                  label={_VALUES.CREATE_VEHICLE}
                  onClick={() => setOpenVehicleModal(!openVehicleModal)}
                />
              ),
            },
          ],
        }}
      >
        <TableComponent
          rows={vehicleFetch.data && vehicleFetch.data.items.length > 0 ? vehicleFetch.data.items : []}
          columns={[
            { id: 'id', label: _VALUES.ID, minWidth: 20 },
            { id: 'registrationNumber', label: _VALUES.REGISTRATIONNUMBER, minWidth: 100 },
            { id: 'manufacturer', label: _VALUES.MANUFACTURER, minWidth: 100 },
            { id: 'model', label: _VALUES.MODEL, minWidth: 100 },
            { id: 'provider', label: _VALUES.PROVIDER, minWidth: 100 },
          ]}
          values={_VALUES}
          totalCount={vehicleFetch.data ? vehicleFetch.data.totalCount : 0}
          baseFilters={filters}
          onFiltersChange={(filters: SearchFilters) => applyTableFilters(filters)}
          loading={vehicleFetch.isFetching}
          onClickRow={(id: number) => navigate(`${_ROUTES.VEHICLES}/${id}`)}
        />
      </MainWrapperComponent>
      <VehicleCreateModal
        modalOpen={(open: boolean) => {
          setOpenVehicleModal(open);
        }}
        openModal={openVehicleModal}
      />
    </Box>
  );
};
