import React from 'react';
import { Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _VALUES } from '../../../resources/constants/values';
import { MainWrapperComponent, TextFieldComponent, SelectField, TextSimpleComponent } from 'techsbcn-storybook';
import { debounce } from 'lodash';
import { UserBase } from '../../../interfaces';
import { EnumToSelect, getEnumKeyByEnumValue, SelectEnum } from '../../../helpers';
import { BooleanEnum } from '../../../enums';

interface BasicDriverInfoFormProps {
  action: (data: any) => void;
  readModel?: UserBase;
  loading: boolean;
  loadingUpdate: boolean;
  id: string;
}

const BasicDriverInfoForm: React.FC<BasicDriverInfoFormProps> = (props) => {
  const BasicInfoSchema = yup.object().shape({
    active: yup.string(),
    name: yup.string().required(_VALUES.REQUIRED.REQUIRED_FIELD),
    surnames: yup.string().required(_VALUES.REQUIRED.REQUIRED_FIELD),
    phone: yup.string(),
    email: yup.string().required(_VALUES.REQUIRED.REQUIRED_FIELD).email(_VALUES.REQUIRED.REQUIRED_EMAIL),
  });
  const {
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BasicInfoSchema),
  });

  const onSubmit = (data: any) => {
    props.action(data);
  };

  const setUpdate = debounce(() => {
    onSubmit(getValues());
  }, 1000);

  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.BASIC_INFO,
      }}
      loadingChildren={props.loading}
      loadingProgressBar={props.loadingUpdate}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={2}>
          <TextSimpleComponent fullWidth noBorders={false} label={_VALUES.ID} value={props.id} />
        </Grid>
        <Grid item xs={12} md={5}>
          <Controller
            control={control}
            defaultValue={props.readModel?.name}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.NAME}
                name={name}
                error={!!errors.name}
                helperText={errors.name?.message}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'name'}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Controller
            control={control}
            defaultValue={props.readModel?.surnames}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.SURNAMES}
                name={name}
                error={!!errors.surnames}
                helperText={errors.surnames?.message}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'surnames'}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            control={control}
            defaultValue={
              props.readModel && props.readModel?.active !== null && props.readModel?.active !== undefined
                ? Boolean(JSON.parse(getEnumKeyByEnumValue(BooleanEnum, props.readModel.active)))
                : undefined
            }
            render={({ field: { onChange, value, name, ref } }) => {
              return (
                <SelectField
                  name={name}
                  inputRef={ref}
                  label={_VALUES.STATUS}
                  placeholder={`${_VALUES.STATUS}...`}
                  options={EnumToSelect(BooleanEnum, 'ACTIVE')}
                  defaultOptions={
                    value !== undefined && value !== null
                      ? SelectEnum(
                          BooleanEnum,
                          [BooleanEnum[value ? BooleanEnum.true : BooleanEnum.false].toString()],
                          'ACTIVE'
                        )
                      : undefined
                  }
                  onChange={(e) => {
                    onChange(Boolean(JSON.parse(e.toString())));
                    setUpdate();
                  }}
                  isClearable={false}
                />
              );
            }}
            name={'active'}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Controller
            control={control}
            defaultValue={props.readModel?.phone}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.PHONE}
                name={name}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'phone'}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Controller
            control={control}
            defaultValue={props.readModel?.email}
            render={({ field: { onChange, value, name, ref } }) => (
              <TextFieldComponent
                label={_VALUES.EMAIL}
                name={name}
                error={!!errors.email}
                helperText={errors.email?.message}
                inputRef={ref}
                onChange={(e) => {
                  onChange(e);
                  setUpdate();
                }}
                value={value}
              />
            )}
            name={'email'}
          />
        </Grid>
      </Grid>
    </MainWrapperComponent>
  );
};

export default BasicDriverInfoForm;
