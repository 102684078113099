import React, { useState } from 'react';
import { ModalComponent, TextFieldComponent, TextSimpleComponent } from 'techsbcn-storybook';
import { _VALUES } from '../../../resources/constants/values';
import { beautyShotCreate, beautyShotUpdate } from '../../../redux/beautyShots/beautyShotsSlice';
import { _ROUTES } from '../../../resources/constants/routes';
import { useAppDispatch } from '../../../helpers';
import { Grid } from '@mui/material';
import { File } from '../../../interfaces';
import ChangeImage from '../../shared/ChangeImage';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { createFile } from '../../../redux/files/filesAPI';

interface BeautyShotCreateModalProps {
  beautyShotModel?: any;
  modalOpen: (open: boolean) => void;
  openModal: boolean;
}

export const BeautyShotCreateModal: React.FC<BeautyShotCreateModalProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const BeautyShotSchema = yup.object().shape({
    name: yup.string().required(_VALUES.REQUIRED.REQUIRED_FIELD),
    template: yup.mixed().nullable().required(_VALUES.REQUIRED.REQUIRED_FIELD),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BeautyShotSchema),
  });

  const dispatch = useAppDispatch();

  const onImageChanged = (data: File) => {
    setImageFile(data);
  };
  const resetForm = () => {
    setLoading(false);
    props.modalOpen(false);
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    if (imageFile && Object.keys(imageFile).length !== 0) {
      createFile(imageFile)
        .then((result) => {
          data.template = result;
          dispatch(
            ((): any => {
              return props.beautyShotModel && props.beautyShotModel?.id
                ? beautyShotUpdate({ beautyShotId: props.beautyShotModel?.id, beautyShot: data })
                : beautyShotCreate(data);
            })()
          ).finally(resetForm);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      dispatch(
        beautyShotUpdate({
          beautyShotId: props.beautyShotModel?.id,
          beautyShot: data,
        })
      ).finally(resetForm);
    }
  };

  return (
    <ModalComponent
      title={(props.beautyShotModel && _VALUES.EDIT_BEAUTYSHOT) || _VALUES.CREATE_BEAUTYSHOT}
      setShow={props.openModal}
      onHide={() => {
        resetForm();
      }}
      cancelButton={{ label: _VALUES.CANCEL }}
      submitButton={{
        label: _VALUES.CONFIRM,
        form: 'beautyshot-create-form',
        type: 'submit',
      }}
      loading={loading}
    >
      <form id="beautyshot-create-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Controller
              control={control}
              defaultValue={props.beautyShotModel?.name || ''}
              render={({ field: { onChange, name, value, ref } }) => (
                <TextFieldComponent
                  label={_VALUES.NAME}
                  placeholder={`${_VALUES.NAME}...`}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  name={name}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                />
              )}
              name={'name'}
            />
          </Grid>
          <Grid item>
            <TextSimpleComponent
              fullWidth={true}
              error={!!errors.template}
              helperText={errors.template?.message}
              value={
                <Controller
                  control={control}
                  defaultValue={props.beautyShotModel?.template}
                  render={({ field: { onChange } }) => (
                    <ChangeImage
                      guid={props.beautyShotModel?.template}
                      updateAction={(data: File) => {
                        onImageChanged(data);
                        onChange(data);
                      }}
                    />
                  )}
                  name={'template'}
                />
              }
            />
          </Grid>
        </Grid>
      </form>
    </ModalComponent>
  );
};
