import { NavigationType, NavigationProps } from 'techsbcn-storybook';
import { _VALUES } from '../../resources/constants/values';
import { _ROUTES } from '../../resources/constants/routes';
import { Scope } from '../../enums/Scope';

export const ItemsNav: NavigationProps[] = [
  {
    id: 'companies',
    title: _VALUES.COMPANIES,
    type: NavigationType.Item,
    route: _ROUTES.COMPANIES,
    persmisionAvailable: Scope[Scope.SUPERADMIN].toString(),
  },
  {
    id: 'drivers',
    title: _VALUES.DRIVERS,
    type: NavigationType.Item,
    route: _ROUTES.DRIVERS,
    persmisionAvailable: Scope[Scope.ADMIN].toString(),
  },
  {
    id: 'vehicles',
    title: _VALUES.VEHICLES,
    type: NavigationType.Item,
    route: _ROUTES.VEHICLES,
    persmisionAvailable: Scope[Scope.ADMIN].toString(),
  },
  {
    id: 'checklist-items',
    title: _VALUES.CHECKLIST_ITEMS,
    type: NavigationType.Item,
    route: _ROUTES.CHECKLIST_ITEMS,
    persmisionAvailable: Scope[Scope.ADMIN].toString(),
  },
  {
    id: 'beautyShots',
    title: _VALUES.BEAUTY_SHOTS,
    type: NavigationType.Item,
    route: _ROUTES.BEAUTY_SHOTS,
    persmisionAvailable: Scope[Scope.ADMIN].toString(),
  },
  {
    id: 'assigments',
    title: _VALUES.ASSIGNMENTS,
    type: NavigationType.Item,
    route: _ROUTES.ASSIGNMENTS,
    persmisionAvailable: Scope[Scope.ADMIN].toString(),
  },
  {
    id: 'incidences',
    title: _VALUES.INCIDENCES,
    type: NavigationType.Item,
    route: _ROUTES.INCIDENCES,
    persmisionAvailable: Scope[Scope.ADMIN].toString(),
  },
];
