import { AuthHeader, ErrorHandler, ResponseHandler } from '../../helpers';
import { SimpleSearchResult, PlanListRequest } from '../../interfaces';
import { _ROUTES } from '../../resources/constants/routes';
import queryString from 'query-string';

const plansListSimple = async (request: PlanListRequest) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: AuthHeader(),
  };

  return new Promise<SimpleSearchResult>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.PLANS}/?${queryString.stringify(request)}`, requestOptions)
      .then(ResponseHandler)
      .then((result: SimpleSearchResult) => {
        resolve(result);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export default plansListSimple;
