import { AuthHeader, ErrorHandler, ResponseBlobHandler, ResponseHandler } from '../../helpers';
import { _ROUTES } from '../../resources/constants/routes';
import { AssignmentBase, UpdateAssignment, CreateAssignment, AssignmentListRequest } from '../../interfaces/index';

export const create = async (request: CreateAssignment) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: AuthHeader(),
    body: JSON.stringify(request),
  };

  return new Promise<number>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.ASSIGNMENTS}`, requestOptions)
      .then(ResponseHandler)
      .then((assignmentId: number) => {
        resolve(assignmentId);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};
export const readAssignment = async (assignmentId: number) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: AuthHeader(),
  };

  return new Promise<AssignmentBase>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.ASSIGNMENTS}/${assignmentId}`, requestOptions)
      .then(ResponseHandler)
      .then((result: AssignmentBase) => {
        resolve(result);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const updateAssignment = async (assignment: UpdateAssignment, id: string) => {
  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: AuthHeader(),
    body: JSON.stringify(assignment),
  };

  return new Promise<void>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.ASSIGNMENTS}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const removeAssignment = async (id: number) => {
  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: AuthHeader(),
  };

  return new Promise<boolean>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.ASSIGNMENTS}/${id}`, requestOptions)
      .then(ResponseHandler)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};

export const createDocument = async (request: AssignmentListRequest) => {
  const requestOptions = {
    method: 'POST',
    headers: AuthHeader(),
    body: JSON.stringify(request),
  };

  return new Promise<any>((resolve, reject) =>
    fetch(`${_ROUTES.API}${_ROUTES.ASSIGNMENTS}/reports`, requestOptions)
      .then(ResponseBlobHandler)
      .then((result: any) => {
        resolve(result);
      })
      .catch((error) => {
        reject(ErrorHandler(error));
      })
  );
};
