import { BasicEntity } from '../shared/BasicEntity';

export interface IncidenceBase {
  id?: number;
  description?: string;
  file?: string;
  createdOn?: Date;
  createdBy?: BasicEntity;
  vehicle?: BasicEntity;
  active?: string;
}

export const IncidenceBaseDefaults: IncidenceBase = {
  description: '',
};
