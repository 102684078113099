import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainWrapperComponent } from 'techsbcn-storybook';
import { CompanyBase, CompanyBaseDefaults, UpdateCompany } from '../../interfaces';
import { readCompany, updateCompany } from '../../redux/companies/companiesAPI';
import { _VALUES } from '../../resources/constants/values';
import BasicCompanyInfoForm from '../../components/companies/forms/BasicCompanyInfoForm';
import FiscalCompanyInfoForm from '../../components/companies/forms/FiscalCompanyInfoForm';
import CompanyContactInfoForm from '../../components/companies/forms/CompanyContactInfoForm';
import CompanyAdminsTable from '../../components/companies/tables/CompanyAdminsTable';
import { deepOmit, isEmptyValues } from '../../helpers';
import * as _ from 'lodash';

export const Company: React.FC = () => {
  const { id } = useParams() as {
    id: string;
  };

  const [company, setCompany] = useState<CompanyBase>();
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  useEffect(() => {
    let isActive = true;
    setLoadingCompany(true);
    readCompany(parseInt(id))
      .then((result) => {
        if (isActive) {
          const companyMerge: any = _.merge(
            deepOmit(JSON.parse(JSON.stringify(CompanyBaseDefaults)), _.isNil),
            deepOmit(JSON.parse(JSON.stringify(result)), _.isNil)
          );
          setCompany(companyMerge);
          setLoadingCompany(false);
        }
      })
      .catch(() => {
        setLoadingCompany(false);
      });

    return () => {
      isActive = false;
    };
  }, [id]);

  const onSubmit = (data: UpdateCompany) => {
    const companyMerge: any = Object.assign(company, data);
    setLoadingUpdate(true);
    updateCompany(parseInt(id), deepOmit(deepOmit(companyMerge, isEmptyValues), isEmptyValues)).finally(() => {
      setLoadingUpdate(false);
    });
  };

  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: `${_VALUES.COMPANY} #${id}`,
        }}
        loading={loadingCompany}
      />
      <Box my={2}>
        <BasicCompanyInfoForm
          action={onSubmit}
          readModel={company}
          loading={loadingCompany}
          loadingUpdate={loadingUpdate}
        />
      </Box>
      <Box my={2}>
        <FiscalCompanyInfoForm
          action={onSubmit}
          readModel={company}
          loading={loadingCompany}
          loadingUpdate={loadingUpdate}
        />
      </Box>
      <Box my={2}>
        <CompanyContactInfoForm
          action={onSubmit}
          readModel={company}
          loading={loadingCompany}
          loadingUpdate={loadingUpdate}
        />
      </Box>
      <Box my={2}>
        <CompanyAdminsTable id={parseInt(id)} />
      </Box>
    </Box>
  );
};
