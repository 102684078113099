import { _ROUTES } from '../../resources/constants/routes';
import {
  AssignmentIncidenceListItem,
  BeautyShotListItem,
  ChecklistItemListItem,
  ListResultCollection,
} from '../../interfaces';
import { apiSlice } from '../apiSlice';
import queryString from 'query-string';
import { ErrorHandler } from '../../helpers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AssignmentListItem } from '../../interfaces/assignments/AssignmentListItem';
import { AssignmentListRequest } from '../../interfaces/assignments/AssignmentListRequest';
import { ReduxStatus } from '../../enums';
import { removeAssignment } from './assignmentsAPI';

const assignmentsEndpoints = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchAssignments: builder.query<ListResultCollection<AssignmentListItem>, AssignmentListRequest>({
        keepUnusedDataFor: 0,
        query: (request) => `${_ROUTES.ASSIGNMENTS}/?${queryString.stringify(request)}`,
        async onQueryStarted(_request, { queryFulfilled }) {
          await queryFulfilled.catch((err) => {
            ErrorHandler(err);
          });
        },
      }),
      fetchAssignmentIncidences: builder.query<AssignmentIncidenceListItem[], number>({
        keepUnusedDataFor: 0,
        query: (request) => `${_ROUTES.ASSIGNMENTS}/${request}${_ROUTES.INCIDENCES}`,
        async onQueryStarted(_request, { queryFulfilled }) {
          await queryFulfilled.catch((err) => {
            ErrorHandler(err);
          });
        },
      }),
      fetchAssignmentBeautyShots: builder.query<BeautyShotListItem[], number>({
        keepUnusedDataFor: 0,
        query: (request) => `${_ROUTES.ASSIGNMENTS}/${request}${_ROUTES.BEAUTY_SHOTS}`,
        async onQueryStarted(_request, { queryFulfilled }) {
          await queryFulfilled.catch((err) => {
            ErrorHandler(err);
          });
        },
      }),
      fetchAssignmentCheckListItems: builder.query<ChecklistItemListItem[], number>({
        keepUnusedDataFor: 0,
        query: (request) => `${_ROUTES.ASSIGNMENTS}/${request}${_ROUTES.CHECKLIST_ITEMS}`,
        async onQueryStarted(_request, { queryFulfilled }) {
          await queryFulfilled.catch((err) => {
            ErrorHandler(err);
          });
        },
      }),
    };
  },
});

interface AssignmentRemoveState {
  status: ReduxStatus;
}

export interface AssignmentState {
  removeAssignment: AssignmentRemoveState;
}

const initialStateAssignments: AssignmentState = {
  removeAssignment: { status: ReduxStatus.Idle },
};

export const assignmentRemove = createAsyncThunk<boolean, number>(
  `${_ROUTES.VEHICLES}/DELETE`,
  async (request: number, { rejectWithValue }) => {
    return await removeAssignment(request).catch((error) => {
      return rejectWithValue(error);
    });
  }
);

export const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState: initialStateAssignments,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assignmentRemove.pending, (state) => {
        state.removeAssignment.status = ReduxStatus.Loading;
      })
      .addCase(assignmentRemove.rejected, (state) => {
        state.removeAssignment.status = ReduxStatus.Failed;
      })
      .addCase(assignmentRemove.fulfilled, (state) => {
        state.removeAssignment.status = ReduxStatus.Succeeded;
      });
  },
});

export const getAssignmentsState = (state: RootState) => state.assignments;
export default assignmentsSlice.reducer;
export const {
  useFetchAssignmentsQuery,
  useFetchAssignmentIncidencesQuery,
  useFetchAssignmentBeautyShotsQuery,
  useFetchAssignmentCheckListItemsQuery,
} = assignmentsEndpoints;
