import React from 'react';
import { Box } from '@mui/material/';
import { _VALUES } from '../../resources/constants/values';
import logo from '../../content/img/error-404.svg';
import { _ROUTES } from '../../resources/constants/routes';
import { useNavigate } from 'react-router-dom';

export const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box className="not-found-page cursor-pointer" onClick={() => navigate(_ROUTES.LOGIN)}>
      <Box
        className="not-found-wrapper"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="img-box">
          <img className="img-404" src={logo} />
        </Box>
      </Box>
    </Box>
  );
};
