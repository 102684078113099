import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@mui/material';
import { Search } from '@mui/icons-material';
import { MainWrapperComponent, TableComponent, TextFieldComponent, SelectField } from 'techsbcn-storybook';
import { useFetchIncidencesQuery } from '../../../redux/incidences/incidencesSlice';
import { _VALUES } from '../../../resources/constants/values';
import { SearchFilters } from '../../../interfaces';
import { BooleanEnum } from '../../../enums/BooleanEnum';
import { EnumToSelect } from '../../../helpers';
import * as _ from 'lodash';
import { _ROUTES } from '../../../resources/constants/routes';

interface IncidencesTableProps {
  vehicleId: number;
}

const IncidencesTable: React.FC<IncidencesTableProps> = (props) => {
  const navigate = useNavigate();

  const defaultFilters: SearchFilters = {
    offset: 0,
    limit: 100,
    orderBy: 'id',
    orderAsc: true,
    page: 1,
    vehicleIds: [props.vehicleId],
  };

  const [filters, setFilters] = useState<SearchFilters>(JSON.parse(JSON.stringify(defaultFilters)));
  const incidencesFetch = useFetchIncidencesQuery(filters);

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFilters(event.target.name, event.target.value);
  };

  const setNewFilters = (name: string, value: any) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[name] = value;
    newFilters.page = 1;
    newFilters.offset = 0;
    setFilters(newFilters);
  };

  const applyTableFilters = (filtersTable: SearchFilters) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters.page = filtersTable.page;
    newFilters.limit = filtersTable.limit;
    newFilters.orderBy = filtersTable.orderBy;
    newFilters.orderAsc = filtersTable.orderAsc;
    newFilters.offset = filtersTable.offset;
    setFilters(newFilters);
  };

  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.INCIDENCES,
        filters: [
          {
            singleFilter: (
              <TextFieldComponent
                name="description"
                label={_VALUES.DESCRIPTION}
                placeholder={`${_VALUES.DESCRIPTION}...`}
                onChange={onHandleChange}
                startIcon={
                  <Icon>
                    <Search />
                  </Icon>
                }
              />
            ),
          },
          {
            singleFilter: (
              <TextFieldComponent
                name="createdOn"
                type="date"
                label={_VALUES.DATE}
                placeholder={`${_VALUES.DATE}...`}
                onChange={_.debounce(async (e) => onHandleChange(e), 1000)}
              />
            ),
          },
          {
            singleFilter: (
              <SelectField
                name="active"
                label={_VALUES.STATUS}
                placeholder={`${_VALUES.STATUS}...`}
                options={EnumToSelect(BooleanEnum, 'OPEN')}
                onChange={(value: any, name: string) => setNewFilters(name, value)}
              />
            ),
          },
        ],
      }}
    >
      <TableComponent
        rows={incidencesFetch.data && incidencesFetch.data.items.length > 0 ? incidencesFetch.data.items : []}
        columns={[
          { id: 'description', label: _VALUES.DESCRIPTION, minWidth: 100 },
          { id: 'createdOn', label: _VALUES.DATE, minWidth: 300, isDate: true },
          {
            id: 'active',
            label: _VALUES.STATUS,
            minWidth: 300,
            isBoolean: { enumType: BooleanEnum, enumSuffix: 'OPEN' },
          },
        ]}
        values={_VALUES}
        totalCount={incidencesFetch.data ? incidencesFetch.data.totalCount : 0}
        baseFilters={filters}
        onFiltersChange={(filters: SearchFilters) => applyTableFilters(filters)}
        loading={incidencesFetch.isFetching}
        onClickRow={(id: number) => navigate(`${_ROUTES.INCIDENCES}/${id}`)}
      />
    </MainWrapperComponent>
  );
};

export default IncidencesTable;
