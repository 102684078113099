import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Icon } from '@mui/material';
import { Search } from '@mui/icons-material';
import {
  ButtonComponent,
  MainWrapperComponent,
  TableComponent,
  TextFieldComponent,
  SelectAsyncField,
} from 'techsbcn-storybook';
import { _VALUES } from '../../resources/constants/values';
import { AssignmentListRequest, SearchFilters, UserListRequest } from '../../interfaces';
import { getAssignmentsState, useFetchAssignmentsQuery } from '../../redux/assignments/assignmentsSlice';
import { _ROUTES } from '../../resources/constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { vehicleListSimple } from '../../redux/vehicles/vehiclesAPI';
import { usersListSimple } from '../../redux/users/usersAPI';
import { Scope } from '../../enums/Scope';
import { AssignmentCreateModal, AssignmentRemoveModal } from '../../components/modals';
import { addNotification, downloadFileBlob, removeNotification, useAppSelector, usePrevious } from '../../helpers';
import { createDocument } from '../../redux/assignments/assignmentsAPI';
import { ReduxStatus } from '../../enums';

export const Assignments: React.FC = () => {
  const downloadReport = () => {
    const id = Math.random().toString(36).substring(7);
    addNotification(_VALUES.DOWNLOADING, 'info', id);
    createDocument(filters)
      .then((result) => {
        downloadFileBlob(result);
        removeNotification(id);
      })
      .catch(() => {
        removeNotification(id);
      });
  };

  const defaultFilters: AssignmentListRequest = {
    offset: 0,
    limit: 100,
    orderBy: 'id',
    orderAsc: true,
    page: 1,
  };

  const defaultDriversSelectFilters: UserListRequest = {
    offset: 0,
    limit: 20,
    orderBy: 'id',
    orderAsc: true,
    page: 1,
    roleIds: [Scope[Scope.DRIVER].toString()],
  };

  const navigate = useNavigate();
  const [openAssignmentModal, setOpenAssignmentModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const [assingmentId, setAssignmentId] = useState(0);
  const [filters, setFilters] = useState<AssignmentListRequest>(JSON.parse(JSON.stringify(defaultFilters)));
  const assignmentsFetch = useFetchAssignmentsQuery(filters);

  const removeAssignment = useAppSelector(getAssignmentsState).removeAssignment;
  const prevAssigmentRemoveState = usePrevious(removeAssignment.status);

  const setNewFilters = (name: string, value: any) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[name] = value;
    newFilters.page = 1;
    newFilters.offset = 0;
    setFilters(newFilters);
  };

  const applyTableFilters = (filtersTable: SearchFilters) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters.page = filtersTable.page;
    newFilters.limit = filtersTable.limit;
    newFilters.orderBy = filtersTable.orderBy;
    newFilters.orderAsc = filtersTable.orderAsc;
    newFilters.offset = filtersTable.offset;
    setFilters(newFilters);
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters[event.target.name] = event.target.value;
    newFilters.page = 1;
    newFilters.offset = 0;
    setFilters(newFilters);
  };

  const onHandleSelectChange = (value: any, name: string) => {
    const checkedValue = value == null ? [] : value;
    setNewFilters(name, checkedValue);
  };

  useEffect(() => {
    if (
      prevAssigmentRemoveState !== undefined &&
      prevAssigmentRemoveState !== ReduxStatus.Succeeded &&
      removeAssignment.status === ReduxStatus.Succeeded
    ) {
      assignmentsFetch.refetch();
    }
  }, [prevAssigmentRemoveState, assignmentsFetch, removeAssignment]);

  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: _VALUES.ASSIGNMENTS,
          searchBar: (
            <TextFieldComponent
              name="query"
              placeholder={`${_VALUES.SEARCH}...`}
              onChange={onHandleChange}
              startIcon={
                <Icon>
                  <Search />
                </Icon>
              }
            />
          ),
          filters: [
            {
              singleFilter: (
                <SelectAsyncField
                  label={_VALUES.VEHICLE}
                  placeholder={`${_VALUES.VEHICLE}...`}
                  name={'vehicleIds'}
                  searchPromise={vehicleListSimple}
                  onChange={onHandleSelectChange}
                  isClearable={true}
                  isMulti={true}
                />
              ),
            },
            {
              singleFilter: (
                <SelectAsyncField
                  label={_VALUES.DRIVER}
                  placeholder={`${_VALUES.DRIVER}...`}
                  name={'driverIds'}
                  filters={defaultDriversSelectFilters}
                  searchPromise={usersListSimple}
                  onChange={onHandleSelectChange}
                  isClearable={true}
                  isMulti={true}
                />
              ),
            },
            {
              singleFilter: (
                <TextFieldComponent
                  name="scheduledFor"
                  label={_VALUES.DATE}
                  placeholder={`${_VALUES.DATE}...`}
                  onChange={onHandleChange}
                  type="date"
                  startIcon={
                    <Icon>
                      <Search />
                    </Icon>
                  }
                />
              ),
            },
          ],
          actions: [
            {
              children: (
                <ButtonComponent
                  disabled={assignmentsFetch?.data?.items.length === 0}
                  label={_VALUES.EXPORT_TO_EXCEL}
                  startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                  onClick={() => downloadReport()}
                />
              ),
            },
            {
              children: (
                <ButtonComponent
                  label={_VALUES.CREATE_ASSIGNMENT}
                  onClick={() => setOpenAssignmentModal(!openAssignmentModal)}
                />
              ),
            },
          ],
        }}
      >
        <TableComponent
          rows={assignmentsFetch.data && assignmentsFetch.data.items.length > 0 ? assignmentsFetch.data.items : []}
          columns={[
            { id: 'id', label: _VALUES.ID, minWidth: 20 },
            { id: 'vehicle.name', label: _VALUES.VEHICLE, minWidth: 100, noSort: true },
            { id: 'driver.name', label: _VALUES.DRIVER, minWidth: 100, noSort: true },
            { id: 'scheduledFor', label: _VALUES.DATE, minWidth: 100, isDate: true },
            { id: 'completedOn', label: _VALUES.CHECK_COMPLETED, minWidth: 100, isDate: true },
            {
              id: 'actions',
              label: _VALUES.ACTIONS,
              action: [
                {
                  children: <FontAwesomeIcon icon={faTrashAlt} />,
                  onClickId: (id: number) => {
                    setAssignmentId(id);
                    setShowRemoveModal(true);
                  },
                },
              ],
            },
          ]}
          values={_VALUES}
          totalCount={assignmentsFetch.data ? assignmentsFetch.data.totalCount : 0}
          baseFilters={filters}
          onFiltersChange={(filters: SearchFilters) => applyTableFilters(filters)}
          loading={assignmentsFetch.isFetching}
          onClickRow={(id: number) => navigate(`${_ROUTES.ASSIGNMENTS}/${id}`)}
        />
      </MainWrapperComponent>
      <AssignmentCreateModal
        modalOpen={(open: boolean) => {
          setOpenAssignmentModal(open);
        }}
        openModal={openAssignmentModal}
      />
      <AssignmentRemoveModal
        id={assingmentId}
        modalOpen={(open: boolean) => setShowRemoveModal(open)}
        openModal={showRemoveModal}
      />
    </Box>
  );
};
