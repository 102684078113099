import React from 'react';
import { MainWrapperComponent } from 'techsbcn-storybook';
import { _VALUES } from '../../../resources/constants/values';
import ChangeImage from '../../shared/ChangeImage';

interface IncidencePhotoProps {
  image?: string;
}

const IncidencePhoto: React.FC<IncidencePhotoProps> = (props) => {
  return (
    <MainWrapperComponent
      headerProps={{
        title: _VALUES.PHOTO,
      }}
    >
      <ChangeImage guid={props.image} />
    </MainWrapperComponent>
  );
};

export default IncidencePhoto;
