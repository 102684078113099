import * as _ from 'lodash';

export function isEmptyValues(value: any) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export function deepOmit(obj: any, predicate?: _.ValueKeyIteratee<any>) {
  const predicateToOmit = predicate;
  function omitFromObject(obj: any) {
    return _.transform(obj, function (result: any, value: any, key: any) {
      result[key] = _.isObject(value) ? omitFromObject(_.omitBy(value, predicateToOmit)) : value;
    });
  }

  return omitFromObject(_.omitBy(obj, predicateToOmit));
}
