import React from 'react';
import { Link } from 'react-router-dom';
import furgoCheckLogo from '../../content/img/furgoCheck.png';
import TechsbcnPoweredComponent from '../../components/shared/TechsbcnPoweredComponent';
import { _VALUES } from '../../resources/constants/values';
import { PublicFormWrapper, LoginForm } from 'techsbcn-storybook';
import { GetUserIdToken, useAppDispatch, useAppSelector } from '../../helpers';
import { _ROUTES } from '../../resources/constants/routes';
import { ReduxStatus } from '../../enums';
import { loginUser, getAuthState } from '../../redux/auth/authSlice';
import { readUser } from '../../redux/users/usersSlice';
import { Box, Typography } from '@mui/material';

export const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const onSubmit = async (data: any) => {
    await dispatch(loginUser(data));
    authState.userAuth.status === ReduxStatus.Succeeded && dispatch(readUser(GetUserIdToken()));
  };

  const authState = useAppSelector(getAuthState);

  return (
    <PublicFormWrapper>
      <LoginForm
        topComponent={
          <Box textAlign="center">
            <img src={furgoCheckLogo} className="login-logo" alt="logo" />
          </Box>
        }
        onClick={onSubmit}
        loading={authState.userAuth.status === ReduxStatus.Loading}
        requiredText={{
          requiredTextLogin: _VALUES.REQUIRED.REQUIRED_MAIL,
          requiredTextPassword: _VALUES.REQUIRED.REQUIRED_PASSWORD,
        }}
        valuesText={{
          buttonText: _VALUES.LOGIN,
          labelLogin: _VALUES.LOGIN,
          placeholderLogin: _VALUES.LOGIN,
          labelPassword: _VALUES.PASSWORD,
          placeholderPassword: _VALUES.PASSWORD,
        }}
        bottomComponent={
          <Box textAlign="center">
            <Typography gutterBottom component="span" className="text-underline cursor-pointer">
              <Link to={_ROUTES.RESET_PASSWORD}> {_VALUES.FORGOT_PASSWORD_TEXT}</Link>
            </Typography>
            <Box pt={3}>
              <TechsbcnPoweredComponent />
            </Box>
          </Box>
        }
      />
    </PublicFormWrapper>
  );
};
