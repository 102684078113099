import React, { useEffect } from 'react';
import { Box, styled, Avatar } from '@mui/material';
import { ButtonComponent, SearchComponent } from 'techsbcn-storybook';
import { _VALUES } from '../../resources/constants/values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { File } from '../../interfaces';
import { getImageByBase64, getImageByBlob, toBase64 } from '../../helpers';
import { readFile } from '../../redux/files/filesAPI';

interface ChangeImageProps {
  updateAction?: (data: File) => void;
  guid?: string;
}

const ChangeImage: React.FC<ChangeImageProps> = (props) => {
  const [profileImg, setProfileImg] = React.useState('');

  useEffect(() => {
    let isActive = true;
    if (props.guid) {
      readFile(props.guid).then((file: any) => {
        if (isActive) {
          setProfileImg(getImageByBlob(file));
        }
      });
    }
    return () => {
      isActive = false;
    };
  }, [props.guid]);

  const onHandleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!file.type.match(/image-*/)) {
        alert('Invalid format');
        return;
      }
      toBase64(file)
        .then((result) => {
          const fileItem: File = {
            name: file.name,
            base64Content: result.replace(/^data:image\/[a-z]+;base64,/, ''),
          };
          fileItem.base64Content && setProfileImg(getImageByBase64(fileItem.base64Content));
          props.updateAction && props.updateAction(fileItem);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <Box className="change-image-component">
      {props.updateAction && (
        <Box my={2}>
          <SearchComponent
            actions={[
              {
                children: (
                  <label htmlFor="change-image-button">
                    <Input
                      accept="image/*"
                      id="change-image-button"
                      multiple
                      type="file"
                      onChange={onHandleImageChange}
                    />
                    <ButtonComponent
                      component="span"
                      label={_VALUES.CHANGE_IMAGE}
                      startIcon={<FontAwesomeIcon icon={faCamera} />}
                    />
                  </label>
                ),
              },
            ]}
          />
        </Box>
      )}
      <Avatar src={profileImg} variant="square" className="image-avatar" />
    </Box>
  );
};

export default ChangeImage;
