import React from 'react';
import { ModalComponent } from 'techsbcn-storybook';
import { useAppDispatch, useAppSelector } from '../../../helpers';
import { _VALUES } from '../../../resources/constants/values';
import { Box } from '@mui/material';
import { _ROUTES } from '../../../resources/constants/routes';
import { getUserState, userRemove } from '../../../redux/users/usersSlice';
import { ReduxStatus, Scope } from '../../../enums';

interface UserRemoveModalProps {
  modalOpen: (open: boolean) => void;
  openModal: boolean;
  id: number;
  role: Scope;
}

export const UserRemoveModal: React.FC<UserRemoveModalProps> = (props) => {
  const userCrud = useAppSelector(getUserState).userCrudState;
  const dispatch = useAppDispatch();

  const onClick = async (data: number) => {
    dispatch(userRemove(data)).finally(() => {
      props.modalOpen(false);
    });
  };

  return (
    <ModalComponent
      title={
        (props.role == Scope.ADMIN && _VALUES.REMOVE_ADMIN) || (props.role == Scope.DRIVER && _VALUES.REMOVE_DRIVER)
      }
      setShow={props.openModal}
      onHide={() => props.modalOpen(false)}
      cancelButton={{ label: _VALUES.CANCEL }}
      submitButton={{
        label: _VALUES.CONFIRM,
        onClick: () => onClick(props.id),
      }}
      loading={userCrud.status === ReduxStatus.Loading}
    >
      <Box>
        {(props.role == Scope.ADMIN && _VALUES.REMOVE_ADMIN_TEXT_INFO) ||
          (props.role == Scope.DRIVER && _VALUES.REMOVE_DRIVER_TEXT_INFO)}
      </Box>
    </ModalComponent>
  );
};
