import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainWrapperComponent } from 'techsbcn-storybook';
import { UserBase, UserBaseDefaults } from '../../interfaces';
import { userRead, updateUser } from '../../redux/users/usersAPI';
import { _VALUES } from '../../resources/constants/values';
import BasicDriverInfoForm from '../../components/drivers/forms/BasicDriverInfoForm';
import AssignedVehiclesTable from '../../components/drivers/tables/AssignedVehiclesTable';
import * as _ from 'lodash';
import { deepOmit, isEmptyValues } from '../../helpers';

export const Driver: React.FC = () => {
  const { id } = useParams() as {
    id: string;
  };

  const [driver, setDriver] = useState<UserBase>();
  const [loadingDriver, setLoadingDriver] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  useEffect(() => {
    let isActive = true;
    setLoadingDriver(true);

    userRead(id)
      .then((result) => {
        if (isActive) {
          const driverMerge: any = _.merge(
            deepOmit(JSON.parse(JSON.stringify(UserBaseDefaults)), _.isNil),
            deepOmit(JSON.parse(JSON.stringify(result)), _.isNil)
          );
          setDriver(driverMerge);
          setLoadingDriver(false);
        }
      })
      .catch(() => {
        setLoadingDriver(false);
      });

    return () => {
      isActive = false;
    };
  }, [id]);

  const onSubmit = (data: UserBase) => {
    setLoadingUpdate(true);
    updateUser(
      parseInt(id),
      Object.assign({} as UserBase, deepOmit(deepOmit(data, isEmptyValues), isEmptyValues))
    ).finally(() => {
      setLoadingUpdate(false);
    });
  };

  return (
    <Box>
      <MainWrapperComponent
        headerProps={{
          title: `${_VALUES.DRIVER} #${id}`,
        }}
        loading={loadingDriver}
      />
      <Box my={2}>
        <BasicDriverInfoForm
          id={id}
          action={onSubmit}
          readModel={driver}
          loading={loadingDriver}
          loadingUpdate={loadingUpdate}
        />
      </Box>
      <Box my={2}>
        <AssignedVehiclesTable driverId={parseInt(id)} />
      </Box>
    </Box>
  );
};
